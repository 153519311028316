// libraries
import Link from 'next/link';
import { useRouter } from 'next/router';

// @parsec
import { styled } from '@parsec/components';

export default function Tabs() {
  return (
    <nav>
      <NavList>
        <NavLink href="/" text="General" />
        <NavLink href="/security" text="Security" />
        <NavLink href="/plan" text="Plan" />
      </NavList>
    </nav>
  );
}

function NavLink(props: { href: string; text: string }) {
  const { href, text } = props;

  const router = useRouter();

  return (
    <NavItem>
      <StyledLink
        href={href}
        active={router.pathname === href}
        passHref
        scroll={false}
      >
        {text}
      </StyledLink>
    </NavItem>
  );
}

const NavList = styled('ul', {
  display: 'flex',
  boxShadow: 'inset 0 -0.1rem 0 $colors$pangoro'
});

const NavItem = styled('li', {
  flex: 1
});

const StyledLink = styled(Link, {
  display: 'block',
  height: '5.4rem',
  lineHeight: '5.4rem',
  fontSize: '$attribution',
  textAlign: 'center',
  variants: {
    active: {
      true: {
        boxShadow: 'inset 0 -0.4rem 0 0 $colors$primary500'
      }
    }
  }
});
