import { useCallback, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

// @parsec
import {
  styled,
  CredentialForm,
  TfaBackupCodes,
  BaseModal,
  ModalSize,
  Button,
  BaseModalProps
} from '@parsec/components';
import { useResetTFABackupCodes } from '@parsec/queries';

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$xlarge'
});

interface BackupCodesModalProps extends BaseModalProps {
  showTfa: boolean | undefined;
  onCloseAutoFocus?: (e: Event) => void;
}

interface FormValues {
  password: string;
  tfa?: string;
}

export default function BackupCodesModal(props: BackupCodesModalProps) {
  const {
    open: openProp,
    onOpenChange,
    showTfa,
    children,
    onCloseAutoFocus
  } = props;
  const [open, setOpen] = useState(openProp);

  const isModalOpen = Boolean(open || openProp); // openProp is used for controlled open

  const resetTFABackupCodes = useResetTFABackupCodes(),
    codes = resetTFABackupCodes.data?.map(code => code.code) || [];

  const formMethods = useForm<FormValues>();

  const resetModal = useCallback(() => {
    formMethods.reset();
    resetTFABackupCodes.reset();
  }, [formMethods, resetTFABackupCodes]);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        resetModal();
      }
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [onOpenChange, resetModal]
  );

  const closeModal = useCallback(() => {
    // added for clarity
    handleOpenChange(false);
  }, [handleOpenChange]);

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = formMethods;

  const onSubmit = handleSubmit(async values => {
    try {
      await resetTFABackupCodes.mutateAsync({
        password: values.password,
        tfa: values.tfa?.toString()
      });
    } catch (_error) {
      // noop, error captured on mutation result
    }
  });

  return (
    <FormProvider {...formMethods}>
      <BaseModal open={isModalOpen} onOpenChange={handleOpenChange}>
        {children ? (
          <BaseModal.Trigger asChild>{children}</BaseModal.Trigger>
        ) : null}
        <BaseModal.Portal>
          <BaseModal.Overlay>
            <BaseModal.Content
              size={ModalSize.Large}
              onCloseAutoFocus={onCloseAutoFocus}
            >
              <BaseModal.Header>
                <BaseModal.Title>Display 2FA Backup Codes</BaseModal.Title>
              </BaseModal.Header>
              <BaseModal.ContentWrapper>
                {codes.length ? (
                  <TfaBackupCodes
                    id="download_backup_codes"
                    codes={codes}
                    onDone={closeModal}
                  />
                ) : (
                  <Form id="reset_backup_codes" onSubmit={onSubmit}>
                    <p>
                      Enter your password to display your two-factor
                      authentication backup codes.
                    </p>
                    <p>
                      Note: this will invalidate any previous backup codes you
                      have saved.
                    </p>

                    <CredentialForm
                      showTfaInput={
                        showTfa ||
                        resetTFABackupCodes.error?.codes.some(
                          code => code.type === 'tfa_incorrect'
                        )
                      }
                    />
                  </Form>
                )}
              </BaseModal.ContentWrapper>
              <BaseModal.Footer
                errorMessage={resetTFABackupCodes.error?.error}
                errorType="error"
              >
                {codes.length ? (
                  <>
                    <Button form="download_backup_codes">Download Codes</Button>
                    <BaseModal.Close asChild>
                      <Button level="secondary">Cancel</Button>
                    </BaseModal.Close>
                  </>
                ) : (
                  <>
                    <Button form="reset_backup_codes" disabled={isSubmitting}>
                      Display Codes
                    </Button>
                    <BaseModal.Close asChild>
                      <Button level="secondary">Cancel</Button>
                    </BaseModal.Close>
                  </>
                )}
              </BaseModal.Footer>
            </BaseModal.Content>
          </BaseModal.Overlay>
        </BaseModal.Portal>
      </BaseModal>
    </FormProvider>
  );
}
