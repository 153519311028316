import { useEffect, useState } from 'react';

import Identify from '@parsec/analytics/src/utils/Identify';
import { useRouter } from 'next/router';

import { team } from '@parsec/kessel';
import {
  useCreateTeam,
  useEstimateTeamSubscription,
  useGetMe
} from '@parsec/queries';
import { parseError } from '@parsec/request';

import { TEAMS_URL } from 'lib/config';
import { param } from 'lib/util/router';
import {
  INTERVAL_MONTHLY,
  INTERVAL_YEARLY,
  TEAM_MONTHLY_CB_PLAN_ID,
  TEAM_YEARLY_CB_PLAN_ID,
  getChargebeePlanID
} from 'lib/util/subscription';

export enum Step {
  BillingIntervalStep,
  BillingInfoStep,
  Confirmation
}

interface PropParams {
  type?: string | string[];
  seats?: string | string[];
  interval?: string | string[];
}

export function useCreateTeamState(params?: PropParams) {
  const me = useGetMe();
  const router = useRouter();
  const team = useCreateTeam();
  const createTeam = team.mutateAsync;
  const submitting = team.isLoading;

  //if have an account
  useEffect(() => {
    if (me && me.data?.team_id) router.push(TEAMS_URL);
  }, [me, router]);

  //if params passed.
  useEffect(() => {
    const intervalParam = param(params?.interval ?? router.query.interval);

    const interval =
      intervalParam === INTERVAL_MONTHLY || intervalParam === 'monthly'
        ? getChargebeePlanID(INTERVAL_MONTHLY, 'standard')
        : intervalParam === INTERVAL_YEARLY || intervalParam === 'yearly'
          ? getChargebeePlanID(INTERVAL_YEARLY, 'standard')
          : undefined;

    const seatParam = param(params?.seats ?? router.query.seats);
    const seats = seatParam ? parseInt(seatParam) : undefined;

    const typeParam = param(params?.type ?? router.query.type);

    const type = !typeParam
      ? undefined
      : parseInt(typeParam) === 1
        ? 'active'
        : 'trial';

    const allParams: { [key: string]: number | string | undefined } = {
      plan_id: interval,
      standard_seats: seats,
      type: type
    };

    //only put forward those that dont have an undefined value
    const validKeys = Object.keys(allParams).filter(
      (key: string) => !!allParams[key]
    );

    if (validKeys.length) {
      const finalParams: { [key: string]: number | string | undefined } = {};
      validKeys.forEach(key => (finalParams[key] = allParams[key]));
      updateReq(finalParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  const [error, setError] = useState('');
  const [step, setStep] = useState(Step.BillingIntervalStep);

  const [req, setReq] = useState<team.CreateTeamReq>({
    name: '',
    standard_seats: 5,
    plan_id: getChargebeePlanID(INTERVAL_MONTHLY, 'standard'),
    token: '',
    type: 'trial',
    customer: {
      email: '',
      billing_address: { line1: '', city: '', state: '', country: '', zip: '' }
    },
    captcha_token: ''
  });

  function updateReq(args: Partial<team.CreateTeamReq>) {
    setError('');
    if (
      (args.standard_seats && args.standard_seats < 5) ||
      args.standard_seats === 0
    ) {
      setError('All teams must have a minimum of 5 seats');
      return;
    }

    if (args.name && args.name.length <= 2) {
      setError('Team Name must be longer than two characters');
      return;
    }
    setReq({ ...req, ...args });
  }

  // NOTE: Encapsulate all trial logic related to team signup here.
  // We cannot leverage the estimate endpoint since estimates only deal with pricing
  // and does not have any notion of trial information.
  const currentTime = new Date();
  // By default, teams will receive a 14 day trial.
  const trialEnd = new Date(currentTime.setDate(currentTime.getDate() + 14));

  const estimate = {
    ...useEstimateTeamSubscription({
      plan_id: req.plan_id,
      seats: req.standard_seats,
      country: req.customer.billing_address.country,
      zip: req.customer.billing_address.zip,
      coupon: req.coupon
    }),
    trialEnd: trialEnd
  };

  async function handleCreateTeam({ redirectUrl }: { redirectUrl: string }) {
    try {
      const res = await createTeam(req);
      Identify({
        userId: me.data?.id,
        marketingOptIn:
          me.data?.marketing_opt_in !== null
            ? me.data?.marketing_opt_in
            : undefined,
        teamId: res.id,
        teamSubscription:
          req.plan_id === TEAM_MONTHLY_CB_PLAN_ID
            ? TEAM_MONTHLY_CB_PLAN_ID
            : TEAM_YEARLY_CB_PLAN_ID
      });
      router.push(redirectUrl);
    } catch (err) {
      const { error, codes } = parseError(err, {
        error: "Couldn't create team."
      });

      const unverifiedUser = codes.some(
        code => code.type === 'user_unverified'
      );

      const errMsg = unverifiedUser
        ? "You must confirm your account's email address before creating a team."
        : error;
      setError(errMsg);
    }
  }

  return {
    submitting,
    error,
    req,
    step,
    estimate,
    setStep,
    updateReq,
    handleCreateTeam
  };
}
