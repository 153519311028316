// libraries
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';

// @parsec
import { ExperimentProvider, initExperiment } from '@parsec/amplitude';
import { DataLayer, GoogleTagManager, OneTrust } from '@parsec/analytics';
import {
  ErrorBoundary,
  INCIDENT_CONFIG,
  IncidentNotificationProvider,
  global,
  injectGlobalStyles,
  setModalAppElement
} from '@parsec/components';
import { clear, cookie } from '@parsec/cookie';
import { useRetryUntilResolved } from '@parsec/hooks';
import { configure } from '@parsec/kessel';
import { QueryProvider } from '@parsec/queries';
import { setAuthRedirect } from '@parsec/redirect';
import { initSentry } from '@parsec/sentry';

// public
import 'public/fonts/fonts.css';

import { SsoDefaultLayout, StripeProvider } from 'components';
import {
  DOWNTIME_URL,
  ENV,
  EXPERIMENT_DEPLOY_KEY,
  KESSEL_URL,
  MAINTENANCE_URL,
  ONETRUST_ENABLED,
  RELEASE,
  SENTRY_DSN
} from 'lib/config';
import { DATA_LAYER_AUTH_EXCLUDE_URLS } from 'lib/dataLayerExcludeUrls';

import background from 'lib/images/wrapper/background.png';
import background2x from 'lib/images/wrapper/background@2x.png';

// not confidential
const FALLBACK_VARIANT_VALUE = 'control';
const DONT_REDIRECT_URL_PARAMS = ['cta', 'my-team', 'me/warp'];

initSentry({ env: ENV, dsn: SENTRY_DSN, release: RELEASE });

const experiment = initExperiment({
  deployKey: EXPERIMENT_DEPLOY_KEY,
  options: {
    fallbackVariant: { value: FALLBACK_VARIANT_VALUE }
  }
});

setModalAppElement('#__next');

const setBackground = global({
  body: {
    background: `$ultraDark url("${background.src}") center no-repeat`,
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    '@retina': {
      backgroundImage: `url("${background2x.src}")`
    }
  }
});

export default function Root({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const [experimentLoaded, setExperimentLoaded] = useState(false);

  const amplitudeExists = useRetryUntilResolved(
    () => typeof window.amplitude !== 'undefined'
  );

  injectGlobalStyles();
  if (!pageProps.noBackground) {
    setBackground();
  }

  useEffect(() => {
    async function initialFetch() {
      await experiment.fetch();
      setExperimentLoaded(true);
    }

    if (amplitudeExists) {
      initialFetch();
    }
  }, [amplitudeExists]);

  // UNCOMMENT WHEN YOU NEED TO DEBUG, DO NOT MERGE INTO LIVE CODE
  // useEffect(() => {
  //   const handleRouteChange = (url: any, { shallow }: any) => {
  //     console.log(
  //       `App is changing to ${url} ${
  //         shallow ? 'with' : 'without'
  //       } shallow routing`
  //     );
  //   };

  //   router.events.on('routeChangeStart', handleRouteChange);

  //   // If the component is unmounted, unsubscribe
  //   // from the event with the `off` method:
  //   return () => {
  //     router.events.off('routeChangeStart', handleRouteChange);
  //   };
  // }, [router]);

  const onInvalidSession = useCallback((status: string) => {
    clear();
    setAuthRedirect(window.location.href);

    if (status === 'NO_COOKIE') {
      if (
        DONT_REDIRECT_URL_PARAMS.some(cta => window.location.href.includes(cta))
      ) {
        // don't redirect bro, we're already going to sign up
        return;
      } else if (window.location.href.includes('?getWarp')) {
        // backwards compatibility
        // TODO: REMOVE ONCE WE HAVE ALL REDIRECTS FROM GETWARP MOVED OVER
        window.location.assign(`/signup`);
      } else if (
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/login/'
      ) {
        // Prevent infinite reloads to login page.
        window.location.assign(`/login`);
      }
    } else if (status === '401' || status === '412') {
      window.location.assign(`/login/?invalidSession=${status}`);
    } else {
      window.location.assign(`/login/?invalidSession=true`);
    }
  }, []);

  const defaultLayout = (page: ReactElement) => (
    <SsoDefaultLayout>{page}</SsoDefaultLayout>
  );

  const getLayout = Component.getLayout ?? defaultLayout;

  // Flag for if we want to run the `useMe` query in DataLayer Component
  const dlAuthEnabled = useMemo(
    () =>
      DATA_LAYER_AUTH_EXCLUDE_URLS.every(
        excludeUrl => excludeUrl !== router.pathname
      ),
    [router.pathname]
  );

  return (
    <ErrorBoundary name="root">
      <QueryProvider kessel={kessel} onInvalidSession={onInvalidSession}>
        {ONETRUST_ENABLED && <OneTrust />}
        <DataLayer authEnabled={dlAuthEnabled} />
        <GoogleTagManager />
        <StripeProvider>
          <IncidentNotificationProvider
            {...INCIDENT_CONFIG}
            externalDowntimeURL={DOWNTIME_URL}
            externalMaintenanceURL={MAINTENANCE_URL}
          >
            <ExperimentProvider
              loaded={experimentLoaded}
              experiment={experiment}
            >
              {getLayout(<Component {...pageProps} />)}
            </ExperimentProvider>
          </IncidentNotificationProvider>
        </StripeProvider>
      </QueryProvider>
    </ErrorBoundary>
  );
}

const kessel = configure({
  kessel: KESSEL_URL,
  downtimeURL: DOWNTIME_URL,
  token: () => cookie().token
});
