import { useCallback, useMemo, useState } from 'react';

import Identify from '@parsec/analytics/src/utils/Identify';

import {
  Button,
  BillingCardPreview as BaseBillingCardPreview,
  styled,
  BaseModalProps,
  BaseModal,
  ModalSize
} from '@parsec/components';
import { WarpPlanID, WarpSubscription, Card } from '@parsec/kessel';
import { useGetWarpBillingCard } from '@parsec/queries';

import { WarpLogo } from 'components';
import {
  BillingInfoForm,
  NewCard as BaseNewCard,
  NewCardData
} from 'components/Billing';
import { BILLING_INFO_FORM_IDS } from 'lib/constants/formIds';
import { useResumeModalState } from 'lib/hooks';

import PurchaseError from '../PurchaseError';
import PurchaseSuccess from '../PurchaseSuccess';
import { HeadBack, Description } from '../shared-components';
import ConfirmationForm from '../WarpConfirmPurchase';
import WarpPricing from '../WarpPricing';

/** STYLES */
const BillingInfoSection = styled('div', {
  padding: '$xlarge'
});

const ExistingCardSection = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 10rem',
  gridTemplateRows: '$space$xxlarge auto',
  paddingBottom: '$xxxlarge'
});

const BillingCardPreview = styled(BaseBillingCardPreview, {
  gridColumnStart: 1,
  gridColumnEnd: 3
});

const StyledP = styled('p', {
  fontWeight: '$bold',
  fontSize: '$body'
});

const EditButton = styled('button', {
  background: 'transparent',
  fontSize: '$attribution',
  lineHeight: '$attribution',
  fontWeight: 'bold',
  color: '$primary500',
  padding: '0',
  justifySelf: 'right',
  alignSelf: 'center',
  cursor: 'pointer'
});

const CancelButton = styled(EditButton, {
  padding: '0',
  position: 'absolute',
  right: '$xlarge'
});

const ContentWrapper = styled(BaseModal.ContentWrapper, {
  padding: 0,
  overflowY: 'auto'
});

const successMessage = {
  resume: {
    title: "Feel that? That's the feeling of an even better Parsec.",
    description: (
      <>
        <p>
          Thanks for resuming your Warp subscription. Your subscription lets us
          continue making Parsec awesome.
        </p>
        <p> Now enough mushy stuff — go enjoy all the perks.</p>
      </>
    )
  },
  resubscribe: {
    title: "Welcome back! That's the feeling of an even better Parsec.",
    description: (
      <>
        <p>
          We&apos;re happy to have you back! Your subscription lets us continue
          making Parsec awesome.
        </p>
        <p>Now enough mushy stuff — go enjoy all the perks.</p>
      </>
    )
  }
};

/** COMPONENT */

function ExistingCard(props: { card: Card; onEditClick?(): void }) {
  const { card, onEditClick } = props;
  return (
    <ExistingCardSection>
      <StyledP>Payment Method</StyledP>
      <EditButton onClick={onEditClick}>Edit</EditButton>
      <BillingCardPreview card={card} />
    </ExistingCardSection>
  );
}

function NewCard(props: { onCancelClick(): void }) {
  const { onCancelClick } = props;
  return (
    <>
      <CancelButton onClick={onCancelClick}>Cancel</CancelButton>
      <BaseNewCard />
    </>
  );
}

interface ResumeModalProps extends BaseModalProps {
  subscription?: WarpSubscription;
  onCloseAutoFocus?: (e: Event) => void;
}

export default function ResumeModal(props: ResumeModalProps) {
  const {
    open: openProp,
    onOpenChange,
    subscription,
    onCloseAutoFocus
  } = props;
  const [open, setOpen] = useState(openProp);
  const isModalOpen = Boolean(open || openProp);

  const isResubscribe = subscription?.status === 'cancelled';

  const {
    error,
    req,
    subtotal,
    loading,
    step,
    estimate,
    setStep,
    setError,
    updateReq,
    handleResume,
    setShouldUpdateCard
  } = useResumeModalState({
    subscription
  });

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        updateReq({ coupon: '' });
      }
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [onOpenChange, updateReq]
  );

  const card = useGetWarpBillingCard();
  const hasCard = Boolean(card.data);

  const [isEditing, setIsEditing] = useState(!hasCard);

  const title = useMemo(() => {
    if (error) {
      return 'Uh oh!';
    } else if (step === 'success') {
      return undefined;
    } else if (isResubscribe) {
      return 'Resubscribe to Warp';
    } else {
      return 'Resume your subscription';
    }
  }, [error, isResubscribe, step]);

  const renderSteps = (): JSX.Element | null => {
    switch (step) {
      case 'pricing':
        return (
          <>
            <ContentWrapper>
              <WarpPricing
                selectedPlanId={req.plan_id as WarpPlanID}
                subtotal={subtotal}
                updateReq={updateReq}
              />
            </ContentWrapper>
            <BaseModal.Footer>
              <Button onClick={() => setStep('billing')}>Next</Button>
              <BaseModal.Close asChild>
                <Button level="secondary" type="button">
                  Cancel
                </Button>
              </BaseModal.Close>
            </BaseModal.Footer>
          </>
        );
      case 'billing':
        return (
          <BillingInfoForm<NewCardData>
            id={BILLING_INFO_FORM_IDS.DEFAULT_ID}
            onSubmit={data => {
              updateReq({
                token: data.token
              });
              setShouldUpdateCard(Boolean(data.token));
              setStep('confirmation');
            }}
          >
            <ContentWrapper>
              <BillingInfoSection>
                {card.data && !isEditing ? (
                  <ExistingCard
                    card={card.data}
                    onEditClick={() => setIsEditing(true)}
                  />
                ) : (
                  <NewCard onCancelClick={() => setIsEditing(false)} />
                )}
              </BillingInfoSection>
            </ContentWrapper>
            <BaseModal.Footer>
              <Button
                type="submit"
                kind="success"
                id="submit_resume_subscription"
                form={BILLING_INFO_FORM_IDS.DEFAULT_ID}
              >
                Review Purchase
              </Button>
              {isResubscribe ? (
                <Button
                  level="secondary"
                  type="button"
                  aria-label="Back to Pricing"
                  onClick={() => setStep('pricing')}
                >
                  Back
                </Button>
              ) : (
                <BaseModal.Close asChild>
                  <Button level="secondary" type="button">
                    Close
                  </Button>
                </BaseModal.Close>
              )}
            </BaseModal.Footer>
          </BillingInfoForm>
        );
      case 'confirmation':
        return (
          <>
            <ContentWrapper>
              <ConfirmationForm
                selectedPlanId={req.plan_id}
                subscription={subscription}
                estimate={estimate.data}
                onConfirm={handleResume}
                onSubscribe={() => {
                  setStep('success');
                  Identify({ warpSubscription: req.plan_id });
                }}
                onError={setError}
                isResume={!isResubscribe}
                coupon={subscription?.coupon}
              />
            </ContentWrapper>
            <BaseModal.Footer>
              <Button
                loading={loading}
                kind="primary"
                form="subscription_confirmation_form"
              >
                Confirm and Submit
              </Button>
              <Button
                level="secondary"
                aria-label="Back to Billing"
                onClick={() => {
                  setStep('billing');
                }}
              >
                Back
              </Button>
            </BaseModal.Footer>
          </>
        );
      case 'success':
        return (
          <>
            <ContentWrapper>
              <PurchaseSuccess
                headline={
                  isResubscribe
                    ? successMessage.resubscribe.title
                    : successMessage.resume.title
                }
                description={
                  isResubscribe
                    ? successMessage.resubscribe.description
                    : successMessage.resume.description
                }
              />
            </ContentWrapper>
            <BaseModal.Footer>
              <BaseModal.Close asChild>
                <HeadBack>Head Back to Parsec</HeadBack>
              </BaseModal.Close>
            </BaseModal.Footer>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <BaseModal open={isModalOpen} onOpenChange={handleOpenChange}>
      <BaseModal.Portal>
        <BaseModal.Overlay>
          <BaseModal.Content
            size={ModalSize.Large}
            onCloseAutoFocus={onCloseAutoFocus}
          >
            <BaseModal.BlockHeader
              type="brand"
              css={{ display: 'grid', gridRowGap: '1.2rem' }}
            >
              {title ? <BaseModal.Title>{title}</BaseModal.Title> : null}
              {step !== 'success' ? (
                <>
                  <WarpLogo />
                  <BaseModal.Description asChild>
                    <Description>
                      Enhance the ways you game, work and whatever else.
                    </Description>
                  </BaseModal.Description>
                </>
              ) : null}
            </BaseModal.BlockHeader>
            {error ? (
              <ContentWrapper>
                <PurchaseError
                  error={error}
                  newModal
                  onStartOver={() => {
                    setError('');
                    setStep(isResubscribe ? 'pricing' : 'billing');
                  }}
                />
              </ContentWrapper>
            ) : (
              renderSteps()
            )}
          </BaseModal.Content>
        </BaseModal.Overlay>
      </BaseModal.Portal>
    </BaseModal>
  );
}
