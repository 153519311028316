// @parsec
import { FormEventHandler, useCallback, useState } from 'react';

import {
  styled,
  AlertModal,
  AlertModalProps,
  Button
} from '@parsec/components';
import { useRemoveAvatar } from '@parsec/queries';

const Form = styled('form', {
  display: 'grid',
  rowGap: '$medium',
  justifyItems: 'center'
});

const AlertDescription = styled(AlertModal.Description, {
  fontSize: '$body',
  lineHeight: '$attribution'
});

interface RemoveAvatarProps extends AlertModalProps {}

export default function RemoveAvatarAlert(props: RemoveAvatarProps) {
  const { open: openProp, onOpenChange, children } = props;
  const [open, setOpen] = useState(false);

  const isAlertOpen = Boolean(open || openProp);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [onOpenChange]
  );

  const removeAvatar = useRemoveAvatar();

  const handleFormSubmit: FormEventHandler = useCallback(
    async e => {
      e.preventDefault();
      try {
        await removeAvatar.mutateAsync();
        handleOpenChange(false);
      } catch (_error) {
        // noop error handled by mutation
      }
    },
    [handleOpenChange, removeAvatar]
  );

  return (
    <AlertModal open={isAlertOpen} onOpenChange={handleOpenChange}>
      {children ? (
        <AlertModal.Trigger asChild>{children}</AlertModal.Trigger>
      ) : null}
      <AlertModal.Portal>
        <AlertModal.Overlay>
          <AlertModal.Content size="small">
            <AlertModal.Header>
              <AlertModal.Title>Remove Profile Picture</AlertModal.Title>
            </AlertModal.Header>
            <AlertModal.ContentWrapper>
              <Form id="remove_avatar" onSubmit={handleFormSubmit}>
                <AlertDescription asChild>
                  <p>Are you sure you want to remove your profile picture?</p>
                </AlertDescription>
              </Form>
            </AlertModal.ContentWrapper>
            <AlertModal.Footer
              errorMessage={removeAvatar.error?.error}
              errorType="error"
            >
              <AlertModal.Action asChild>
                <Button kind="error" form="remove_avatar">
                  Remove
                </Button>
              </AlertModal.Action>
              <AlertModal.Cancel asChild>
                <Button level="secondary">Cancel</Button>
              </AlertModal.Cancel>
            </AlertModal.Footer>
          </AlertModal.Content>
        </AlertModal.Overlay>
      </AlertModal.Portal>
    </AlertModal>
  );
}
