import { ReactNode, useRef, useState } from 'react';

import {
  type TurnstileInstance,
  AlertModal,
  Button,
  CaptchaActions,
  CaptchaWidget
} from '@parsec/components';

import { TURNSTILE_SITE_KEY } from 'lib/config';

interface ResendConfirmEmailModalProps {
  onResend(values: { captcha_token: string }): void;
  children: ReactNode;
  actionName: CaptchaActions;
}

const ResendConfirmEmailModal = ({
  onResend,
  children,
  actionName
}: ResendConfirmEmailModalProps) => {
  const [captchaToken, setCaptchaToken] = useState('');
  const captchaRef = useRef<TurnstileInstance | null>(null);

  return (
    <AlertModal>
      <AlertModal.Trigger asChild>{children}</AlertModal.Trigger>
      <AlertModal.Portal>
        <AlertModal.Overlay>
          <AlertModal.Content size="small">
            <AlertModal.Header>
              <AlertModal.Title>Security Check</AlertModal.Title>
              <AlertModal.Description>
                Verify that you are human to proceed.
              </AlertModal.Description>
            </AlertModal.Header>
            <AlertModal.ContentWrapper>
              <CaptchaWidget
                ref={captchaRef}
                siteKey={TURNSTILE_SITE_KEY}
                action={actionName}
                onSuccess={(token: string) => setCaptchaToken(token)}
              />
            </AlertModal.ContentWrapper>
            <AlertModal.Footer>
              <AlertModal.Action asChild>
                <Button
                  disabled={!captchaToken.length}
                  onClick={() => {
                    try {
                      onResend({ captcha_token: captchaToken });
                    } finally {
                      // Reset the captcha widget so we are granted a new token
                      setCaptchaToken('');
                      captchaRef.current?.reset();
                    }
                  }}
                >
                  Resend Verification
                </Button>
              </AlertModal.Action>
              <AlertModal.Cancel asChild>
                <Button level="secondary">Cancel</Button>
              </AlertModal.Cancel>
            </AlertModal.Footer>
          </AlertModal.Content>
        </AlertModal.Overlay>
      </AlertModal.Portal>
    </AlertModal>
  );
};

export default ResendConfirmEmailModal;
