import { useCallback, useState } from 'react';

import {
  Time,
  styled,
  Button,
  BaseModalProps,
  AlertModal
} from '@parsec/components';
import { useWarpTrialDate } from '@parsec/hooks';
import {
  useCancelWarpSubscription,
  useGetWarpSubscription
} from '@parsec/queries';

import { WarpLogo } from 'components';

interface Props extends BaseModalProps {
  trial?: boolean;
  onError?(message: string): void;
  onCloseAutoFocus?: (e: Event) => void;
}

export default function UnsubscribeModal(props: Props) {
  const {
    open: openProp,
    onOpenChange,
    trial = false,
    onError,
    onCloseAutoFocus
  } = props;
  const [open, setOpen] = useState(openProp);
  const [step, setStep] = useState(1);

  const isModalOpen = Boolean(open || openProp); // openProp is used for controlled open

  const cancelWarpSubscription = useCancelWarpSubscription();

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        cancelWarpSubscription.reset();
      }
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [cancelWarpSubscription, onOpenChange]
  );

  const handleCancelSubscription = async () => {
    try {
      await cancelWarpSubscription.mutateAsync();
    } catch (_err) {
      onError?.(`Couldn't cancel ${trial ? 'trial' : 'subscription'}`);
      handleOpenChange(false);
    }
    setStep(2);
  };

  return (
    <AlertModal open={isModalOpen} onOpenChange={handleOpenChange}>
      <AlertModal.Portal>
        <AlertModal.Overlay>
          <AlertModal.Content size="large" onCloseAutoFocus={onCloseAutoFocus}>
            {step === 1 ? (
              <AlertModal.Header>
                <AlertModal.Title css={{ textTransform: 'uppercase' }}>
                  Are you sure you want to{' '}
                  {trial ? 'cancel your Warp trial?' : 'unsubscribe from Warp?'}
                </AlertModal.Title>
              </AlertModal.Header>
            ) : (
              <AlertModal.Header>
                <WarpLogo />
                <AlertModal.Description>
                  Warp misses you already.
                </AlertModal.Description>
              </AlertModal.Header>
            )}
            <AlertModal.ContentWrapper css={{ overflowY: 'auto' }}>
              {step === 1 ? (
                <Wrapper>
                  <UnsubscribeStep />
                </Wrapper>
              ) : (
                <SuccessBody data-testid="success">
                  {trial
                    ? "You've successfully cancelled your trial. After the 14 day trial period ends, you will be back to using plain ol' Parsec"
                    : `You’ve successfully unsubscribed from Warp. After the billing period ends, you’ll be back to using plain ol’ Parsec. Remember: you can re-enroll in Warp at any time.`}
                </SuccessBody>
              )}
            </AlertModal.ContentWrapper>
            <AlertModal.Footer
              errorMessage={cancelWarpSubscription.error?.error}
              errorType="error"
            >
              {step === 1 ? (
                <>
                  <Button
                    kind="error"
                    disabled={cancelWarpSubscription.isLoading}
                    onClick={handleCancelSubscription}
                  >
                    Yes, {trial ? 'Cancel' : 'Unsubscribe'}
                  </Button>

                  <AlertModal.Cancel asChild>
                    <Button level="secondary">Back</Button>
                  </AlertModal.Cancel>
                </>
              ) : (
                <AlertModal.Cancel asChild>
                  <Button>Got It</Button>
                </AlertModal.Cancel>
              )}
            </AlertModal.Footer>
          </AlertModal.Content>
        </AlertModal.Overlay>
      </AlertModal.Portal>
    </AlertModal>
  );
}

function UnsubscribeStep() {
  const warp = useGetWarpSubscription();
  const trialDate = useWarpTrialDate();

  const subPrice = warp.data?.plan_unit_price_cents;
  const subInterval = warp.data?.billing_interval;

  const subNextBillingDate = warp.data?.current_term_end ?? undefined;

  const trial = warp.data?.status === 'in_trial';
  const trialEnd = trialDate?.endDate;

  const endDate = trial ? trialEnd : subNextBillingDate;

  return (
    <>
      {endDate && (
        <AlertModal.Description unstyled asChild>
          <div>
            <p>
              You&apos;ll keep all your Warp perks until{' '}
              <strong>
                <Time date={new Date(endDate)} />
              </strong>
              {!trial ? ', the end of your current billing period.' : '.'}
            </p>
            <br />
            <p>
              After that, you&apos;ll lose your Warp features and won&apos;t be
              charged{!trial && ' again'}.
            </p>
          </div>
        </AlertModal.Description>
      )}

      <Actions>
        <Stretchy>
          <h4>Subscription Updates</h4>
          <Plan>
            <PlanPrice>
              <PlanLabel>
                Parsec Warp {`${subInterval}ly `}
                cancelled
              </PlanLabel>
              <span>
                {subPrice && formatCurrency(subPrice / 100)}/{subInterval}
              </span>
            </PlanPrice>
          </Plan>
          {!trial && (
            <Disclaimer>
              Remember: you can restart your subscription at any time. Hope
              you&apos;ll be back.
            </Disclaimer>
          )}
        </Stretchy>
      </Actions>
    </>
  );
}

const Plan = styled('div', {
  marginTop: '$small',
  display: 'flex',
  alignItems: 'center',
  color: '$error500'
});

const PlanPrice = styled('div', {
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between'
});

const PlanLabel = styled('span', {
  fontWeight: 'bold'
});

const Actions = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto auto 1fr',
  gridTemplateRows: 'auto auto',
  gridTemplateAreas: '"text text text" "unsubscribe keep ."',
  gridGap: '1.2rem',
  justifyContent: 'start',
  justifyItems: 'start',
  backgroundColor: '$computerBlack'
});

const Stretchy = styled('div', {
  gridArea: 'text',
  justifySelf: 'stretch',
  padding: '$xlarge'
});

const Disclaimer = styled('p', {
  marginTop: '$xxlarge',
  color: '#f9fafc4c'
});

const SuccessBody = styled('p', {
  marginTop: '$xxxlarge'
});

function formatCurrency(amount: number, decimal = true) {
  const string = amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return decimal ? string : string.slice(0, -3);
}

const Wrapper = styled('div', {
  display: 'grid',
  gap: '$xlarge'
});
