import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import {
  TeamAppConfig,
  TeamAppConfigV2,
  appRuleData,
  paginatedAppRuleData
} from './schema';

export interface GetAppRulesReq {
  team_id: string;
  offset: number;
  limit: number;
}

export const getAppRules = (options: ApiOptions) => (req: GetAppRulesReq) => {
  /**
   * Gets a paginated list of AppRules for a Team.
   */
  const { team_id, offset, limit } = req;
  return request({
    type: Method.GET,
    url: `${options.kessel}/teams/${team_id}/app-rules?offset=${offset}&limit=${limit}`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware,
    validator: paginatedAppRuleData
  });
};

/******************************************************************************
 * Get an AppRule
 ******************************************************************************/

export interface GetAppRuleReq {
  team_id: string;
  app_rule_id: string;
}
export const getAppRule =
  (options: ApiOptions) =>
  /**
   * Gets an AppRule
   */
  (req: GetAppRuleReq) => {
    const { team_id, app_rule_id } = req;
    return request({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/app-rules/${app_rule_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: appRuleData
    });
  };

/******************************************************************************
 * Create an AppRule
 ******************************************************************************/

export interface CreateAppRuleReq {
  team_id: string;
  name: string;
  app_config?: TeamAppConfig;
  enforce_tfa?: boolean | null;
  enforce_saml?: boolean | null;
  enforce_ip_verification?: boolean | null;
  client_scope_expiry?: number | null;
  force_team_computer_rules?: boolean | null;
}

export const createAppRule =
  (options: ApiOptions) =>
  /**
   * Creates an AppRule
   */
  (req: CreateAppRuleReq) => {
    const { team_id, ...body } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/app-rules`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: appRuleData,
      body
    });
  };

/******************************************************************************
 * Create an AppRuleV2
 ******************************************************************************/
export interface CreateAppRuleV2Req {
  team_id: string;
  name: string;
  app_config_v2?: TeamAppConfigV2;
  enforce_tfa?: boolean | null;
  enforce_saml?: boolean | null;
  enforce_ip_verification?: boolean | null;
  client_scope_expiry?: number | null;
  force_team_computer_rules?: boolean | null;
}
export const createAppRuleV2 =
  (options: ApiOptions) =>
  /**
   * Creates an AppRule
   */
  (req: CreateAppRuleReq) => {
    const { team_id, ...body } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/v2/teams/${team_id}/app-rules`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: appRuleData,
      body
    });
  };

/******************************************************************************
 * Update an AppRule
 ******************************************************************************/

export interface UpdateAppRuleReq {
  team_id: string;
  app_rule_id: string;
  name?: string;
  app_config?: Partial<TeamAppConfig>;
  enforce_saml?: boolean | null;
  enforce_tfa?: boolean | null;
  enforce_ip_verification?: boolean | null;
  client_scope_expiry?: number | null;
  force_team_computer_rules?: boolean | null;
}

export const updateAppRule =
  (options: ApiOptions) =>
  /**
   * Updates an AppRule
   */
  (req: UpdateAppRuleReq) => {
    const { team_id, app_rule_id, ...body } = req;
    return request({
      type: Method.PUT,
      url: `${options.kessel}/teams/${team_id}/app-rules/${app_rule_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: appRuleData,
      body
    });
  };

/******************************************************************************
 * Update an AppRule V2
 ******************************************************************************/
export interface UpdateAppRuleV2Req {
  team_id: string;
  app_rule_id: string;
  name?: string;
  app_config_v2?: Partial<TeamAppConfigV2>;
  enforce_saml?: boolean | null;
  enforce_tfa?: boolean | null;
  enforce_ip_verification?: boolean | null;
  client_scope_expiry?: number | null;
  force_team_computer_rules?: boolean | null;
}
export const updateAppRuleV2 =
  (options: ApiOptions) =>
  /**
   * Updates an AppRule
   */
  (req: UpdateAppRuleReq) => {
    const { team_id, app_rule_id, ...body } = req;
    return request({
      type: Method.PUT,
      url: `${options.kessel}/v2/teams/${team_id}/app-rules/${app_rule_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: appRuleData,
      body
    });
  };

/******************************************************************************
 * Delete an AppRule
 ******************************************************************************/

export interface DeleteAppRuleReq {
  team_id: string;
  app_rule_id: string;
}

export const deleteAppRule =
  (options: ApiOptions) =>
  /**
   * Deletes an AppRule
   */
  (req: DeleteAppRuleReq) => {
    const { team_id, app_rule_id } = req;
    return request({
      type: Method.DELETE,
      url: `${options.kessel}/teams/${team_id}/app-rules/${app_rule_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Assign an AppRule to TeamMember or TeamMachine
 ******************************************************************************/

export interface AssignAppRuleReq {
  team_id: string;
  app_rule_id: string;
  user_ids?: number[];
  team_machine_ids?: string[];
}

export const assignAppRule =
  (options: ApiOptions) => (req: AssignAppRuleReq) => {
    const { team_id, app_rule_id, ...body } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/app-rules/${app_rule_id}/assign`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Send an email for enforcing TFA
 ******************************************************************************/

export interface SendAppRuleEnforceTfaEmailReq {
  team_id: string;
  app_rule_id: string;
}

export const sendAppRuleEnforceTfaEmail =
  (options: ApiOptions) => (req: SendAppRuleEnforceTfaEmailReq) => {
    const { team_id, app_rule_id } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/app-rules/${app_rule_id}/send-enforce-tfa-email`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Send an email for enforcing SAML
 ******************************************************************************/

export interface SendAppRuleEnforceSamlEmailReq {
  team_id: string;
  app_rule_id: string;
}

export const sendAppRuleEnforceSamlEmail =
  (options: ApiOptions) => (req: SendAppRuleEnforceSamlEmailReq) => {
    const { team_id, app_rule_id } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/app-rules/${app_rule_id}/send-enforce-saml-email`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };
