export const SUPPORT_LINKS = {
  BILLING_SUPPORT:
    'https://support.parsec.app/hc/en-us/sections/32361192775188-Billing',
  CONTACT_US: 'https://support.parsec.app/hc/en-us/requests/new',
  GUEST_ACCESS_SUPPORT:
    'https://support.parsec.app/hc/en-us/sections/32361192763412-How-to-s',
  MAIL_TO_SALES: 'mailto:sales@parsec.app',
  DECREASING_SEATS:
    'https://support.parsec.app/hc/en-us/articles/32361388615572-Managing-Seats#decreasing_seats',
  GROUP_HOSTS_SUPPORT:
    'https://support.parsec.app/hc/en-us/articles/32361552479892-Manage-Groups#where-are-my-hosts',
  LEAVING_PARSEC_SURVEY:
    'https://iteratehq.com/parsec/5a286e28fb200e0001008089',
  OUT_OF_DATE_HOSTS_SUPPORT:
    'https://support.parsec.app/hc/en-us/sections/32361180097684-Notices'
};
