// libraries
import { useCallback, useState } from 'react';

// @parsec
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  styled,
  CredentialForm,
  BaseModal,
  ModalSize,
  Button,
  BaseModalProps
} from '@parsec/components';
import { useDisableTFA } from '@parsec/queries';

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$xlarge'
});

const formSchema = z.object({
  password: z.string().min(1, { message: 'Password is required.' }),
  tfa: z.number({ message: '2FA code is required.' })
});

type FormValues = z.infer<typeof formSchema>;

interface DisableTfaModalProps extends BaseModalProps {
  showTfa: boolean | undefined;
  onCloseAutoFocus?: (e: Event) => void;
}

export default function NewDisableTfaModal({
  open: openProp,
  onOpenChange,
  showTfa,
  children,
  onCloseAutoFocus,
  ...rest
}: DisableTfaModalProps) {
  const [open, setOpen] = useState(openProp);
  const isModalOpen = Boolean(open || openProp); // openProp is used for controlled open

  const disableTFA = useDisableTFA();

  const formMethods = useForm<FormValues>({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: zodResolver(formSchema)
  });

  const resetModal = useCallback(() => {
    disableTFA.reset(); // reset mutation errors
    formMethods.reset(); // reset form
  }, [disableTFA, formMethods]);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        resetModal();
      }
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [onOpenChange, resetModal]
  );

  const {
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = formMethods;

  const onSubmit: SubmitHandler<FormValues> = async values => {
    try {
      await disableTFA.mutateAsync({
        password: values.password,
        tfa: values.tfa?.toString()
      });
      handleOpenChange(false); // close and reset modal
    } catch (_error) {
      // noop, error captured on mutation result
    }
  };

  return (
    <BaseModal open={isModalOpen} onOpenChange={handleOpenChange} {...rest}>
      {children ? <BaseModal.Trigger>{children}</BaseModal.Trigger> : null}
      <BaseModal.Portal>
        <BaseModal.Overlay>
          <BaseModal.Content
            size={ModalSize.Medium}
            onCloseAutoFocus={onCloseAutoFocus}
          >
            <BaseModal.Header>
              <BaseModal.Title>
                Disable Two-Factor Authentication
              </BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.ContentWrapper>
              <FormProvider {...formMethods}>
                <Form id="disable_tfa" onSubmit={handleSubmit(onSubmit)}>
                  <BaseModal.Description unstyled asChild>
                    <p>
                      Disabling two-factor authentication means that Parsec will
                      no longer ask you for an authentication code when logging
                      in.
                    </p>
                  </BaseModal.Description>
                  <CredentialForm
                    showTfaInput={
                      showTfa ||
                      disableTFA.error?.codes.some(
                        code => code.type === 'tfa_incorrect'
                      )
                    }
                  />
                </Form>
              </FormProvider>
            </BaseModal.ContentWrapper>
            <BaseModal.Footer
              errorMessage={disableTFA.error?.error}
              errorType="error"
            >
              <Button
                kind="error"
                form="disable_tfa"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                Disable 2FA
              </Button>
              <BaseModal.Close asChild>
                <Button level="secondary" disabled={isSubmitting}>
                  Cancel
                </Button>
              </BaseModal.Close>
            </BaseModal.Footer>
          </BaseModal.Content>
        </BaseModal.Overlay>
      </BaseModal.Portal>
    </BaseModal>
  );
}
