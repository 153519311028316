// libraries
import { useCallback, useEffect, useRef } from 'react';

import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import Head from 'next/head';
import { useRouter } from 'next/router';

import {
  styled,
  Avatar,
  Button,
  Divider,
  Tooltip,
  BaseModal,
  ModalSize,
  AlertModal
} from '@parsec/components';
import { useCookie } from '@parsec/cookie';
import {
  useGetMe,
  useGetTeam,
  useGetTeamMember,
  useUpdateMarketingAttr
} from '@parsec/queries';

import { Setting, AuthWrapper } from 'components';
import NewChangeAvatarModal from 'components/NewChangeAvatarModal';
import NewDeleteAccountAlert from 'components/NewDeleteAccountAlert';
import NewEditAccountModal from 'components/NewEditAccountModal';
import NewRemoveAvatarAlert from 'components/NewRemoveAvatarAlert';

const Account: NextPageWithLayout = () => {
  const router = useRouter();

  // if user is not authed but is at account, they should be redirected to login
  const { token } = useCookie();

  // un-authed, send to login
  useEffect(() => {
    if (!token) {
      router.push('/login');
    }
  }, [router, token]);

  const me = useGetMe();

  const { mutateAsync: updateMarketingAttr } = useUpdateMarketingAttr();

  // TODO: update me summary to include is_scim?
  const teamQuery = useGetTeam();
  const teamMemberQuery = useGetTeamMember(me.data?.id);

  const isScim =
    teamQuery.data?.capabilities.settings.is_scim_enabled &&
    teamMemberQuery.data?.is_scim;

  // overflowing text ref
  const usernameRef = useRef<HTMLParagraphElement>(null);

  const handleOptOut = useCallback(async () => {
    await updateMarketingAttr({ marketing_opt_in: false });
  }, [updateMarketingAttr]);

  const handleOptIn = useCallback(async () => {
    await updateMarketingAttr({ marketing_opt_in: true });
  }, [updateMarketingAttr]);

  const renderMarketingOptIn = () => {
    if (me.data?.marketing_opt_in) {
      return (
        <>
          Opted In{' '}
          <MarketingOptOutModal onOptOut={handleOptOut}>
            <Button level="link" kind="error">
              Disable
            </Button>
          </MarketingOptOutModal>
        </>
      );
    } else if (me.data?.marketing_opt_in === null) {
      return (
        <MarketingNeutralOptModal onOptIn={handleOptIn} onOptOut={handleOptOut}>
          <Button level="link" kind="primary">
            Set Preference
          </Button>
        </MarketingNeutralOptModal>
      );
    }

    return (
      <MarketingOptInModal onOptIn={handleOptIn}>
        <Button level="link" kind="primary">
          Opt-in
        </Button>
      </MarketingOptInModal>
    );
  };

  return (
    <>
      <Head>
        <title>Account Settings | Parsec</title>
      </Head>

      <H2>Basic Settings</H2>
      <p>You can change these at any time.</p>

      <SettingList>
        <Setting
          as="li"
          title="Profile Picture"
          description="A nice identifier for your profile and friends."
        >
          {me.data ? (
            <>
              <Avatar
                userId={me.data.id}
                nonce={me.data.avatar_nonce}
                size={36}
              />
              <NewChangeAvatarModal
                userId={me.data.id}
                nonce={me.data.avatar_nonce}
              >
                <Button level="link" kind="primary">
                  Change
                </Button>
              </NewChangeAvatarModal>
              <NewRemoveAvatarAlert>
                <Button kind="error" level="link">
                  Remove
                </Button>
              </NewRemoveAvatarAlert>
            </>
          ) : null}
        </Setting>

        <Setting
          as="li"
          title="Username"
          description="Choose whatever name suits you best."
        >
          {me.data ? (
            <>
              <Tooltip
                triggerRef={usernameRef}
                showOnOverflow={true}
                tooltipText={me.data.name}
              >
                <EllipsisText ref={usernameRef}>{me.data.name}</EllipsisText>
              </Tooltip>
              {!isScim && (
                <NewEditAccountModal id={me.data.id} name={me.data.name}>
                  <Button level="link" kind="primary">
                    Edit
                  </Button>
                </NewEditAccountModal>
              )}
            </>
          ) : null}
        </Setting>

        <Setting
          as="li"
          title="Email"
          description="Used for security, account ID and communications."
        >
          {me.data ? (
            <>
              {me.data.email}
              {!isScim && (
                <Button
                  level="link"
                  kind="primary"
                  onClick={() => router.push('/change-email')}
                >
                  Edit
                </Button>
              )}
            </>
          ) : null}
        </Setting>
        {me.data ? (
          <Setting
            as="li"
            title="Offers and Product Updates"
            description={
              <>
                Parsec and Unity may send me{' '}
                <a
                  href="https://create.unity.com/marketingactivities?_ga=2.36720550.708690487.1685630706-1605599102.1672861974"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Marketing Activities
                </a>{' '}
                (including via email and social media).
              </>
            }
          >
            {renderMarketingOptIn()}
          </Setting>
        ) : null}
      </SettingList>

      <Divider />
      <H2>Advanced Settings</H2>

      <SettingList>
        <Setting
          as="li"
          title="Delete Account"
          description={
            <>
              We are here to have fun. If you are frustrated with something
              about Parsec or you need some help, check our{' '}
              <a href="https://support.parsec.app">support page</a> or{' '}
              <a href="https://discordapp.com/invite/cQjEGFy">Discord</a>. We
              would love to help.
            </>
          }
        >
          {me.data ? (
            <NewDeleteAccountAlert email={me.data.email}>
              <Button kind="error" level="secondary">
                Delete Account
              </Button>
            </NewDeleteAccountAlert>
          ) : null}
        </Setting>
      </SettingList>
    </>
  );
};

Account.getLayout = page => <AuthWrapper>{page}</AuthWrapper>;

export default Account;

const MarketingOptInModal = ({
  children,
  onOptIn
}: {
  children: ReactNode;
  onOptIn: () => Promise<void>;
}) => {
  return (
    <BaseModal>
      <BaseModal.Trigger asChild>{children}</BaseModal.Trigger>
      <BaseModal.Portal>
        <BaseModal.Overlay>
          <BaseModal.Content size={ModalSize.Small}>
            <BaseModal.Header>
              <BaseModal.Title>Edit Preferences</BaseModal.Title>
            </BaseModal.Header>
            <ContentWrapper>
              <P>
                You&apos;ll receive communications for special offers and
                product updates from Parsec. You can opt-out at any time from
                your account page.
              </P>
            </ContentWrapper>
            <BaseModal.Footer>
              <Button kind="primary" onClick={onOptIn}>
                Confirm
              </Button>

              <BaseModal.Close asChild>
                <Button level="secondary" kind="neutral">
                  Cancel
                </Button>
              </BaseModal.Close>
            </BaseModal.Footer>
          </BaseModal.Content>
        </BaseModal.Overlay>
      </BaseModal.Portal>
    </BaseModal>
  );
};

const MarketingOptOutModal = ({
  children,
  onOptOut
}: {
  children: ReactNode;
  onOptOut: () => Promise<void>;
}) => {
  return (
    <AlertModal>
      <AlertModal.Trigger asChild>{children}</AlertModal.Trigger>
      <AlertModal.Portal>
        <AlertModal.Overlay>
          <AlertModal.Content size="small">
            <AlertModal.Header>
              <AlertModal.Title
                css={{
                  fontFamily: '$newDefault',
                  fontSize: '$subtitle',
                  fontWeight: '$bold',
                  lineHeight: '2.9rem'
                }}
              >
                Edit Preferences
              </AlertModal.Title>
              <VisuallyHidden.Root>
                <AlertModal.Description>
                  If you opt-out, you&apos;ll no longer receive marketing
                  activities (including offers and product updates) from Unity
                  and Parsec. You can always opt back in later if you want to
                  continue receiving communications. If you choose to not
                  subscribe, you&apos;ll still receive important transactional
                  emails.
                </AlertModal.Description>
              </VisuallyHidden.Root>
            </AlertModal.Header>
            <AlertModal.ContentWrapper css={{ padding: '1.8rem $xxlarge' }}>
              <P>
                If you opt-out, you&apos;ll no longer receive marketing
                activities (including offers and product updates) from Unity and
                Parsec. You can always opt back in later if you want to continue
                receiving communications.
              </P>
              <P>
                If you choose to not subscribe, you&apos;ll still receive
                important transactional emails.
              </P>
            </AlertModal.ContentWrapper>
            <AlertModal.Footer>
              <AlertModal.Action asChild>
                <Button kind="error" onClick={onOptOut}>
                  Opt-out
                </Button>
              </AlertModal.Action>
              <AlertModal.Cancel asChild>
                <Button level="secondary" kind="neutral">
                  Cancel
                </Button>
              </AlertModal.Cancel>
            </AlertModal.Footer>
          </AlertModal.Content>
        </AlertModal.Overlay>
      </AlertModal.Portal>
    </AlertModal>
  );
};

const MarketingNeutralOptModal = ({
  children,
  onOptIn,
  onOptOut
}: {
  children: ReactNode;
  onOptIn: () => Promise<void>;
  onOptOut: () => Promise<void>;
}) => {
  return (
    <BaseModal>
      <BaseModal.Trigger asChild>{children}</BaseModal.Trigger>
      <BaseModal.Portal>
        <BaseModal.Overlay>
          <BaseModal.Content size={ModalSize.Small}>
            <BaseModal.Header>
              <BaseModal.Title>Edit Preferences</BaseModal.Title>
            </BaseModal.Header>
            <ContentWrapper>
              <P>
                I agree to have Marketing Activities directed to me by and
                receive marketing and promotional information from Unity,
                including via email and social media{' '}
                <OptionalSpan>(optional)</OptionalSpan>.
              </P>
            </ContentWrapper>
            <BaseModal.Footer>
              <Button kind="error" onClick={onOptIn}>
                Opt-In
              </Button>
              <BaseModal.Close asChild>
                <Button level="secondary" kind="neutral" onClick={onOptOut}>
                  Not Interested
                </Button>
              </BaseModal.Close>
            </BaseModal.Footer>
          </BaseModal.Content>
        </BaseModal.Overlay>
      </BaseModal.Portal>
    </BaseModal>
  );
};

const OptionalSpan = styled('span', { color: '$rhyhorn' });

const ContentWrapper = styled(BaseModal.ContentWrapper, {
  padding: '1.8rem $xxlarge'
});

const P = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '$newInfo',
  lineHeight: '$info',

  '& + &': {
    paddingTop: '2.4rem'
  }
});

const H2 = styled('h2', {
  fontFamily: '$default',
  fontSize: '$attribution',
  lineHeight: '$attribution',
  textTransform: 'uppercase',
  marginTop: '$xxxlarge',
  marginBottom: '$small'
});

const SettingList = styled('ul', {
  margin: '$xxxlarge 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '$xxxlarge'
});

const EllipsisText = styled('p', {
  maxWidth: '290px',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});
