import Link from 'next/link';

import { Button, styled } from '@parsec/components';
import { ELOQUA_LINKS } from '@parsec/constants';
import { useMediaQuery } from '@parsec/hooks';

import { MARKETING_URL } from 'lib/config';

import WarpLogo from './WarpLogo';

import Teams from 'lib/images/header/teams.svg';

import graphicsTablet from 'lib/images/plan/graphicsTablet.png';
import graphicsTablet2x from 'lib/images/plan/graphicsTablet@2x.png';
import palette from 'lib/images/plan/palette.png';
import palette2x from 'lib/images/plan/palette@2x.png';
import privacy from 'lib/images/plan/privacy.png';
import privacy2x from 'lib/images/plan/privacy@2x.png';
import systemPreferences from 'lib/images/plan/systemPreferences.png';
import systemPreferences2x from 'lib/images/plan/systemPreferences@2x.png';
import tv from 'lib/images/plan/tv.png';
import tv2x from 'lib/images/plan/tv@2x.png';
import windowParagraph from 'lib/images/plan/windowParagraph.png';
import windowParagraph2x from 'lib/images/plan/windowParagraph@2x.png';

const teamBullets = [
  {
    title: 'Professional Tools',
    description:
      'Multimonitor streaming, improved colors, advanced display settings, and more make Parsec work for work.',
    img: windowParagraph,
    img2x: windowParagraph2x
  },
  {
    title: 'Centralized Admin',
    description: `One modern dashboard for managing your team's computers, deploying team-wide settings, and controlling how teammates connect.`,
    img: systemPreferences,
    img2x: systemPreferences2x
  },
  {
    title: 'Secure Workflows',
    description:
      'Peer-to-peer encrypted connections to only members of your team, enforced SSO, and granular hardware permissions means your work is safe with us.',
    img: privacy,
    img2x: privacy2x
  }
];

const warpBullets = [
  {
    title: 'Better Color',
    description:
      '4:4:4 color mode makes everything sharper, crisper, and colory-er. Available for NVIDIA Hosts running GTX 900 or better.',
    img: palette,
    img2x: palette2x
  },
  {
    title: 'Extra Display Options',
    description: `Connect in privacy mode, launch virtual displays, and use two displays simultaneously.`,
    img: tv,
    img2x: tv2x
  },
  {
    title: 'Pen + Tablet Support',
    description:
      'Plug in your favorite drawing tablet and boom, it just works. Available for Windows 8+ clients and Windows 10 hosts.',
    img: graphicsTablet,
    img2x: graphicsTablet2x
  }
];

const subscriptions = {
  team: {
    type: 'team' as const,
    subText1: 'Parsec for Teams Subscription',
    subText2: 'Powering Remote Work from Anywhere',
    body: 'Parsec for Teams lets you deploy Parsec across your organization, so that folks can connect to their workstations, collaborate on the fly, test and review material, and even run events.',

    href: `/my-team?journey=plan_teams`,
    buttonText: 'Sign Up Your Team',
    buttonKind: 'neutral' as const,
    bullets: teamBullets
  },
  warp: {
    type: 'warp' as const,
    subText1: 'Warp Subscription',
    subText2: 'A More Professional Parsec for Individuals',
    body: (
      <>
        Upgrade for visual enhancements, added settings, and more controls that
        add up to a super powered Parsec. It’s only <strong>$9.99</strong> a
        month or <strong>$99.99</strong> a year (saving you about $20).
      </>
    ),
    buttonText: 'Subscribe to Warp',
    buttonKind: 'brand' as const,
    bullets: warpBullets
  },
  resubscribe_warp: {
    type: 'resubscribe_warp' as const,
    subText1: 'Warp Subscription',
    subText2: 'A More Professional Parsec for Individuals',
    body: (
      <>
        Upgrade for visual enhancements, added settings, and more controls that
        add up to a super powered Parsec. It’s only <strong>$9.99</strong> a
        month or <strong>$99.99</strong> a year (saving you about $20).
      </>
    ),
    buttonText: 'Resubscribe to Warp',
    buttonKind: 'brand' as const,
    bullets: warpBullets
  }
};

interface SubscriptionCardProps {
  columns: boolean;
  subscriptionChoice: 'warp' | 'team' | 'resubscribe_warp';
  showCreateModal?: ((focusEl?: HTMLButtonElement) => void) | (() => void);
  trialed: boolean;
}

export default function GetSubscriptionCard({
  columns,
  subscriptionChoice,
  trialed,
  showCreateModal
}: SubscriptionCardProps) {
  const retina = !useMediaQuery('(min-width: 640px)');

  const sub = subscriptions[subscriptionChoice];

  const renderActions = () => {
    if (sub.type === 'team') {
      return (
        <>
          <StyledAnchor href={sub.href} passHref>
            <TeamsButton
              kind={sub.buttonKind}
              size="medium"
              aria-label="Start Teams Trial"
            >
              {!trialed ? 'Start Trial' : sub.buttonText}
            </TeamsButton>
          </StyledAnchor>

          <StyledLink href={ELOQUA_LINKS.CONTACT_US} target="_blank">
            Get in Touch
          </StyledLink>
          <StyledLink href={`${MARKETING_URL}/teams`}>Learn More</StyledLink>
        </>
      );
    } else if (sub.type === 'warp') {
      return (
        <ButtonBox>
          <StyledAnchor href="/me/warp?journey=plan_warp" passHref>
            <Button
              kind={sub.buttonKind}
              size="medium"
              aria-label="Start Warp Trial"
            >
              {!trialed ? 'Start Trial' : sub.buttonText}
            </Button>
          </StyledAnchor>
          <MoreInfoWrapper>
            <MoreInfoText>
              Read more about
              <MoreInfo as="a" href={`${MARKETING_URL}/warp`}>
                Warp
              </MoreInfo>
            </MoreInfoText>
          </MoreInfoWrapper>
        </ButtonBox>
      );
    } else {
      // Resubscribe to Warp
      return (
        <ButtonBox>
          <Button
            onClick={e => showCreateModal?.(e.currentTarget)}
            kind={sub.buttonKind}
            size="medium"
            aria-label="Start Warp Trial"
          >
            {!trialed ? 'Start Trial' : sub.buttonText}
          </Button>
          <MoreInfoWrapper>
            <MoreInfoText>
              Read more about
              <MoreInfo as="a" href={`${MARKETING_URL}/warp`}>
                Warp
              </MoreInfo>
            </MoreInfoText>
          </MoreInfoWrapper>
        </ButtonBox>
      );
    }
  };

  return (
    <CardWrapper columns={columns} border={sub.type === 'warp'}>
      <div>
        <TitleContent padding={sub.type}>
          <Subtext>{sub.subText1}</Subtext>
          <div>
            {sub.type === 'team' ? (
              <TeamsLogo />
            ) : (
              <WarpLogo css={{ width: '13.4rem' }} />
            )}
          </div>
          <strong>{sub.subText2}</strong>
        </TitleContent>
        <Body>
          <p>{sub.body}</p>
          <Actions>{renderActions()}</Actions>
        </Body>
        {sub.type === 'team' && (
          <MoreInfoText>
            Need an Enterprise plan?{' '}
            <MoreInfo href={ELOQUA_LINKS.CONTACT_US} target="_blank">
              Contact our Sales team.
            </MoreInfo>
          </MoreInfoText>
        )}
      </div>
      <Highlights>
        {sub.bullets.map(bullet => (
          <Bullet
            key={bullet.title}
            title={bullet.title}
            description={bullet.description}
            img={bullet.img.src}
            img2x={bullet.img2x.src}
            retina={retina}
          />
        ))}
      </Highlights>
    </CardWrapper>
  );
}

function Bullet(props: {
  title: string;
  description: string;
  img: string;
  img2x: string;
  retina: boolean;
}) {
  const { title, description, img, img2x, retina } = props;

  return (
    <BulletInfo>
      <Image src={retina ? img2x : img} alt="" />
      <BulletText>
        <strong>{title}</strong>
        <Subtext>{description}</Subtext>
      </BulletText>
    </BulletInfo>
  );
}

const StyledAnchor = styled(Link, {
  display: 'grid'
});

const CardWrapper = styled('div', {
  display: 'grid',
  borderTop: '.1rem solid $pangoro',
  padding: '$xxxlarge $xxlarge 4.8rem',
  alignItems: 'center',

  variants: {
    columns: {
      true: {
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gap: '3.5rem'
      },
      false: {
        rowGap: '$xxxlarge'
      }
    },
    border: {
      true: {
        borderTop: '.1rem solid $pangoro'
      }
    }
  }
});

const Subtext = styled('p', {
  fontSize: '$info',
  color: '$rhyhorn',
  lineHeight: '$info'
});

const TeamsLogo = styled(Teams, {
  height: '2.7rem'
});

const Body = styled('div', {
  fontSize: '$info',
  lineHeight: '$info',
  display: 'grid',
  rowGap: '$xxxxlarge'
});

const TeamsButton = styled(Button, {
  color: '$computerBlack',
  background: '#FFAD11' // no token for this
});

const Highlights = styled('ul', {
  display: 'grid',
  rowGap: '2.6rem'
});

const TitleContent = styled('div', {
  display: 'grid',
  rowGap: '1.5rem',

  variants: {
    padding: {
      warp: { paddingBottom: '$medium' },
      team: {
        paddingBottom: '$xxxlarge'
      },
      resubscribe_warp: { paddingBottom: '$medium' }
    }
  }
});

const Actions = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'minmax(0, 1fr) .5fr .5fr',
  fontWeight: '$body',
  lineHeight: '$body',
  columnGap: '1.5rem'
});

const StyledLink = styled('a', {
  fontWeight: 'bold',
  color: '$consoleWhite',
  display: 'grid',
  alignItems: 'center',
  cursor: 'pointer',
  fontSize: '$body',
  lineHeight: '$body'
});

const MoreInfoText = styled('p', {
  fontSize: '1rem',
  lineHeight: '1rem',
  color: '$rhyhorn',
  marginTop: '$medium',
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '$small',
  width: 'fit-content'
});

const MoreInfoWrapper = styled('div', {
  display: 'grid',
  justifyContent: 'center'
});

const BulletInfo = styled('li', {
  display: 'grid',
  gridTemplateColumns: '4.6rem auto',
  columnGap: '1.7rem',
  height: '5rem',
  alignItems: 'start'
});

const BulletText = styled('div', {
  display: 'grid'
});

const Image = styled('img', {
  width: '4.6rem',
  height: 'auto'
});

const MoreInfo = styled('a', {
  fontSize: '1rem',
  lineHeight: '1rem',
  color: '$primary500',
  fontWeight: 'bold',
  cursor: 'pointer'
});

const ButtonBox = styled('div', {
  display: 'flex',
  flexDirection: 'column'
});
