// libraries
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FieldValues, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  type TurnstileInstance,
  Button,
  CaptchaActions,
  CaptchaWidget,
  ErrorMessage,
  FieldLabel,
  Input,
  styled,
  Loading,
  Icon
} from '@parsec/components';
import { useLogIn, useSendTfaResetEmail, useResetTfa } from '@parsec/queries';

import { useAlert } from 'components';
import { TURNSTILE_SITE_KEY } from 'lib/config';
import { TFA_RESET_OTP_FORM_ID } from 'lib/constants/formIds';

import Actions from '../Actions';

import hero from 'lib/images/login@2x.png';
import otpHero2x from 'lib/images/login_setpass@2x.png';

//STYLES

const StyledForm = styled('form', {
  display: 'grid',
  gridAutoFlow: 'inherit',
  rowGap: 'inherit',
  width: '30rem',
  justifySelf: 'center',
  maxWidth: '100%',
  '@large': {
    width: '38rem'
  }
});

const Container = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$xlarge',
  transition: '0.25s width ease-in-out'
});

const Hero = styled('div', {
  margin: '0 auto'
});

const Title = styled('h2', {
  fontFamily: '$heading',
  fontSize: '$title',
  lineHeight: '$title',
  textAlign: 'left',
  variants: {
    centered: { true: { textAlign: 'center' } },
    space: {
      true: {
        paddingTop: '$xlarge'
      }
    }
  }
});

const LinkButton = styled('button', {
  color: '$primary500',
  fontWeight: 'bold',
  cursor: 'pointer',
  fontSize: 'inherit',
  variants: {
    color: {
      blue: { color: '$primary500' },
      white: { color: '$consoleWhite' }
    }
  }
});

const Recover = styled('p', {
  fontSize: '$info',
  textAlign: 'center'
});

interface UserDetailsCtx {
  email: string;
  password: string;
}

const UserDetailsContext = createContext<UserDetailsCtx | undefined>(undefined);

const useUserDetails = () => {
  const context = useContext(UserDetailsContext);
  if (!context) {
    throw new Error(
      'useUserDetails must be used within a <UserDetailsContext.Provider>'
    );
  }
  return context;
};

interface TfaProps {
  email: string;
  password: string;
  onSuccess(): void;
  onBack(): void;
  id: string;
}

interface FormValues extends FieldValues {
  tfa: string;
}

enum Screen {
  Prompt,
  SendOtpEmail,
  EnterOtp,
  ResetSuccess
}

export default function Tfa(props: TfaProps) {
  const { email, password, onSuccess, onBack, id } = props;

  const [screen, setScreen] = useState(Screen.Prompt);
  const router = useRouter();

  const value = useMemo(() => ({ email, password }), [email, password]);

  // CAPTCHA
  // TODO: We're disabling Captcha verification on the login endpoints until
  // all of the issues in this Epic (https://jira.unity3d.com/browse/PARSEC-2147)
  // are resolved.
  // const [captchaToken, setCaptchaToken] = useState('');
  // const captchaRef = useRef<TurnstileInstance | null>(null);

  const renderSteps = () => {
    switch (screen) {
      case Screen.Prompt: {
        return (
          <TfaForm
            formId={id}
            onBack={onBack}
            onReset={() => {
              setScreen(Screen.SendOtpEmail);
            }}
            onSuccess={onSuccess}
            // TODO: We're disabling Captcha verification on the login endpoints until
            // all of the issues in this Epic (https://jira.unity3d.com/browse/PARSEC-2147)
            // are resolved.
            //   setCaptchaToken={setCaptchaToken}
            // setCaptchaBound={setCaptchaBound}
          />
        );
      }
      case Screen.SendOtpEmail: {
        return (
          <TFAResetEmail
            onConfirm={() => setScreen(Screen.EnterOtp)}
            onBack={() => setScreen(Screen.Prompt)}
          />
        );
      }
      case Screen.EnterOtp: {
        return (
          <EnterOtpPage
            onSuccess={() => setScreen(Screen.ResetSuccess)}
            onBack={() => setScreen(Screen.SendOtpEmail)}
          />
        );
      }
      case Screen.ResetSuccess: {
        // reload page since we're already on the login page
        return <TfaDeactivated onNext={() => router.reload()} />;
      }
      default:
        return <Loading loading />;
    }
  };

  return (
    <Container>
      <Head>
        <title>Log in to Parsec | Parsec</title>
      </Head>
      <UserDetailsContext.Provider value={value}>
        {renderSteps()}
      </UserDetailsContext.Provider>
    </Container>
  );
}

interface TfaFormProps {
  formId: string;
  onReset(): void;
  onBack(): void;
  onSuccess(): void;
  // TODO: We're disabling Captcha verification on the login endpoints until
  // all of the issues in this Epic (https://jira.unity3d.com/browse/PARSEC-2147)
  // setCaptchaToken: Dispatch<SetStateAction<string>>;
}

function TfaForm({ onBack, onReset, onSuccess, formId }: TfaFormProps) {
  const { email, password } = useUserDetails();

  // Form methods
  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  });
  const { register, handleSubmit } = methods;

  const logIn = useLogIn();

  const handleTfaLogin = async ({ tfa }: FormValues) => {
    try {
      logIn.reset();
      // TODO: We're disabling Captcha verification on the login endpoints until
      // all of the issues in this Epic (https://jira.unity3d.com/browse/PARSEC-2147)
      // are resolved.
      //   await logIn.mutateAsync({
      //     email,
      //     password,
      //     tfa,
      //     captcha_token: captchaToken
      //   });
      await logIn.mutateAsync({
        email,
        password,
        tfa,
        // Since we aren't validating the captcha_token, set it to an empty string to satisfy
        // the schema requirements
        captcha_token: ''
      });
      onSuccess();
    } finally {
      // Reset the captcha widget so we are granted a new token
      // TODO: We're disabling Captcha verification on the login endpoints until
      // all of the issues in this Epic (https://jira.unity3d.com/browse/PARSEC-2147)
      // are resolved.
      //  setCaptchaToken('');
      //  captchaBound?.reset();
    }
  };

  return (
    <StyledForm
      id={formId}
      method="post"
      onSubmit={handleSubmit(handleTfaLogin)}
    >
      <Title>Log in to Parsec</Title>
      <p>Enter your authenticator app code or a backup code.</p>
      <FieldLabel hasError={Boolean(logIn.error)}>
        <FieldLabel.Label label="Code">
          <Input
            {...register('tfa')}
            name="tfa"
            type="numeric"
            placeholder="123456"
            autoFocus
          />
        </FieldLabel.Label>
      </FieldLabel>
      {logIn.error ? <ErrorMessage>{logIn.error?.error}</ErrorMessage> : null}

      {/* 
        TODO: We're disabling Captcha verification on the login endpoints until
          all of the issues in this Epic (https://jira.unity3d.com/browse/PARSEC-2147)
          are resolved.
      <CaptchaWidget
        siteKey={TURNSTILE_SITE_KEY}
        onSuccess={(token: string) => {
          setCaptchaToken(token);
        }}
        action={CaptchaActions.Login}
      /> */}

      <Actions>
        <LinkButton color="white" type="button" onClick={onBack}>
          Back
        </LinkButton>
        <Button wide type="submit" id="submit_login_tfa">
          Log In
        </Button>
      </Actions>

      <Recover>
        Can&apos;t access your two-factor authentication codes?{' '}
        <LinkButton type="button" onClick={onReset}>
          Send a reset email to your recovery account
        </LinkButton>
        .
      </Recover>
    </StyledForm>
  );
}

interface TFAResetEmailProps {
  onBack(): void;
  onConfirm(): void;
}

function TFAResetEmail(props: TFAResetEmailProps) {
  const { onBack, onConfirm } = props;
  const [captchaToken, setCaptchaToken] = useState('');
  const captchaRef = useRef<TurnstileInstance | null>(null);

  const sendTfaResetEmail = useSendTfaResetEmail();

  const { email, password } = useUserDetails();

  const handleSendOtpEmail = async () => {
    try {
      sendTfaResetEmail.reset();
      await sendTfaResetEmail.mutateAsync({
        email,
        password,
        captcha_token: captchaToken
      });
      onConfirm();
    } finally {
      setCaptchaToken('');
      captchaRef.current?.reset();
    }
  };

  return (
    <>
      <Hero>
        <Image src={hero.src} alt="" width={250} height={125} />
      </Hero>
      <Title>Reset 2FA</Title>
      {sendTfaResetEmail.error ? (
        <ErrorMessage>{sendTfaResetEmail.error?.error}</ErrorMessage>
      ) : null}
      <p>An email with recovery instructions will be sent to you.</p>
      <CaptchaWidget
        ref={captchaRef}
        siteKey={TURNSTILE_SITE_KEY}
        action={CaptchaActions.ResetTFA}
        onSuccess={(token: string) => {
          setCaptchaToken(token);
        }}
      />
      <Actions>
        <LinkButton color="white" type="button" onClick={onBack}>
          Back
        </LinkButton>
        <Button
          wide
          type="button"
          onClick={handleSendOtpEmail}
          loading={sendTfaResetEmail.isLoading || !captchaToken}
        >
          Confirm
        </Button>
      </Actions>
    </>
  );
}

const EnterOtpWrapper = styled('div', {
  maxWidth: '49.4rem',
  padding: '0 6rem',
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$xxlarge'
});

const ResendWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '$xxlarge',
  alignItems: 'center'
});

const Centered = styled('div', {
  textAlign: 'center'
});

const OtpForm = styled('form', {});

const otpFormSchema = z.object({
  code: z.string().min(1)
});

type OtpFormValues = z.infer<typeof otpFormSchema>;

interface EnterOtpPageProps {
  onBack(): void;
  onSuccess(): void;
}

function EnterOtpPage({ onBack, onSuccess }: EnterOtpPageProps) {
  const [captchaToken, setCaptchaToken] = useState('');
  const captchaRef = useRef<TurnstileInstance | null>(null);
  const [alert, setAlert] = useAlert();

  const { email, password } = useUserDetails();
  const sendTfaOtpEmail = useSendTfaResetEmail();
  const resetTfa = useResetTfa();

  const formMethods = useForm<OtpFormValues>({
    resolver: zodResolver(otpFormSchema),
    defaultValues: {
      code: ''
    }
  });

  const { handleSubmit, register } = formMethods;

  const handleResendEmail = async () => {
    try {
      sendTfaOtpEmail.reset(); // reset both mutation errors
      resetTfa.reset();

      await sendTfaOtpEmail.mutateAsync({
        email,
        password,
        captcha_token: captchaToken
      });
      setAlert({
        kind: 'success',
        title: 'One-time passcode resent!',
        message: 'Please check your email'
      });
    } catch (_error) {
      // no-op error is handled by mutation
    } finally {
      setCaptchaToken('');
      captchaRef.current?.reset();
    }
  };

  const onResetTfa = async (values: OtpFormValues) => {
    try {
      resetTfa.reset();
      await resetTfa.mutateAsync({
        email,
        password,
        code: values.code,
        captcha_token: captchaToken
      });
      onSuccess();
    } catch (_error) {
      // no-op error is handled by mutation
    } finally {
      setCaptchaToken('');
      captchaRef.current?.reset();
    }
  };

  return (
    <EnterOtpWrapper>
      <Title>One-time Passcode</Title>
      <Hero>
        <Image src={otpHero2x.src} alt="" width={250} height={125} />
      </Hero>
      {alert}
      {resetTfa.error ? (
        <ErrorMessage>{resetTfa.error?.error}</ErrorMessage>
      ) : null}
      <OtpForm id={TFA_RESET_OTP_FORM_ID} onSubmit={handleSubmit(onResetTfa)}>
        <p>
          Enter your one-time passcode and 2FA will be deactivated on your
          account
        </p>
        <FieldLabel css={{ padding: '$xxlarge 0 $large 0' }}>
          <FieldLabel.Label label="One-Time Passcode">
            <Input
              {...register('code', {
                required: true
              })}
            />
          </FieldLabel.Label>
        </FieldLabel>
        <CaptchaWidget
          ref={captchaRef}
          siteKey={TURNSTILE_SITE_KEY}
          action={CaptchaActions.ConfirmResetTFACode}
          onSuccess={(token: string) => {
            setCaptchaToken(token);
          }}
        />
        <Actions css={{ paddingTop: '3.2rem' }}>
          <Button level="link" onClick={onBack}>
            Back
          </Button>
          <Button
            type="submit"
            loading={
              !captchaToken || resetTfa.isLoading || sendTfaOtpEmail.isLoading
            }
          >
            Deactivate 2FA
          </Button>
        </Actions>
      </OtpForm>
      <ResendWrapper>
        <Centered>
          Haven&apos;t received the email?
          <br />
          Click resend and we&apos;ll try again.
        </Centered>
        <Button
          onClick={handleResendEmail}
          icon={<Icon name="send" />}
          level="secondary"
          iconPosition="left"
          loading={
            resetTfa.isLoading || sendTfaOtpEmail.isLoading || !captchaToken
          }
        >
          Resend Email
        </Button>
        {sendTfaOtpEmail.error ? (
          <ErrorMessage>{sendTfaOtpEmail.error?.error}</ErrorMessage>
        ) : null}
      </ResendWrapper>
    </EnterOtpWrapper>
  );
}

const DeactivatedWrapper = styled('div', {
  maxWidth: '49.4rem',
  padding: '0 5.7rem'
});

const CenteredImage = styled(Image, {
  margin: '0 auto'
});

const P = styled('p', {
  paddingTop: '$xxxlarge'
});

const COUNT_DOWN_SECONDS = 5;

interface TfaDeactivatedProps {
  onNext(): void;
}

function TfaDeactivated({ onNext }: TfaDeactivatedProps) {
  const [timer, setTimer] = useState(COUNT_DOWN_SECONDS);

  useEffect(() => {
    const counter = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }

      if (timer - 1 <= 0) {
        onNext();
      }
    }, 1000);

    return () => {
      clearInterval(counter);
    };
  }, [onNext, setTimer, timer]);

  return (
    <DeactivatedWrapper>
      <Hero>
        <CenteredImage
          src={otpHero2x.src}
          alt="decorative image of a key going into a doorway"
          width={250}
          height={125}
        />
      </Hero>
      <Title centered space>
        2FA Deactivated
      </Title>
      <P id="continue-button">
        Two Factor Authentication has been successfully deactivated. This page
        will automatically return to login in a moment, but you may click the
        button below to advance if needed.
      </P>
      <Actions css={{ marginTop: '$xlarge' }}>
        <Button aria-describedby="continue-button" onClick={onNext}>
          Continuing in {timer}
        </Button>
      </Actions>
    </DeactivatedWrapper>
  );
}
