import { useCallback, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  styled,
  FieldLabel,
  Input,
  Button,
  BaseModal,
  ModalSize,
  CredentialForm,
  BaseModalProps
} from '@parsec/components';
import { useSetTFARecoveryEmail } from '@parsec/queries';

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$xlarge'
});

interface RecoveryEmailModalProps extends BaseModalProps {
  primaryAccountEmail: string;
  currentRecoveryEmail?: string;
  showTfa: boolean | undefined;
  onCloseAutoFocus?: (e: Event) => void;
}

const formSchema = z
  .object({
    primaryAccountEmail: z.string(),
    email: z.string({ required_error: 'Email is required.' }).email({
      message: 'Invalid email address'
    }),
    password: z.string().min(1, { message: 'Password is required.' }),
    tfa: z.number({ message: '2FA is required.' })
  })
  .refine(({ primaryAccountEmail, email }) => primaryAccountEmail !== email, {
    message: 'Must not be the same as primary account email.',
    path: ['email']
  });

type FormValues = z.infer<typeof formSchema>;

export default function RecoveryEmailModal(props: RecoveryEmailModalProps) {
  const {
    open: openProp,
    onOpenChange,
    primaryAccountEmail,
    currentRecoveryEmail,
    showTfa,
    onCloseAutoFocus
  } = props;
  const [open, setOpen] = useState(openProp);
  const isModalOpen = Boolean(open || openProp); // openProp is used for controlled open

  const setTFARecoveryEmail = useSetTFARecoveryEmail();

  const formMethods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      primaryAccountEmail,
      email: currentRecoveryEmail ?? ''
    }
  });

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid, isSubmitting }
  } = formMethods;

  const resetModal = useCallback(() => {
    setTFARecoveryEmail.reset(); // reset mutation errors
    formMethods.reset(); // reset form
  }, [setTFARecoveryEmail, formMethods]);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        resetModal();
      }
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [onOpenChange, resetModal]
  );

  const onSubmit: SubmitHandler<FormValues> = async (values, e) => {
    e?.preventDefault();
    try {
      await setTFARecoveryEmail.mutateAsync({
        email: values.email,
        password: values.password,
        tfa: values.tfa?.toString()
      });
      handleOpenChange(false);
    } catch (_err) {
      return;
    }
  };

  return (
    <BaseModal open={isModalOpen} onOpenChange={handleOpenChange}>
      <BaseModal.Portal>
        <BaseModal.Overlay>
          <BaseModal.Content
            size={ModalSize.Large}
            onCloseAutoFocus={onCloseAutoFocus}
          >
            <BaseModal.Header>
              <BaseModal.Title>2FA Recovery Email</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.ContentWrapper>
              <FormProvider {...formMethods}>
                <Form id="set_recovery_email" onSubmit={handleSubmit(onSubmit)}>
                  <p>
                    Add a secondary email address. If you lose access to your
                    two-factor authentication app and your backup codes, we will
                    use it to recover access to your account.
                  </p>
                  <FieldLabel hasError={Boolean(formErrors.email)}>
                    <FieldLabel.Label label="Recovery Email" required>
                      <Input type="email" {...register('email')} />
                    </FieldLabel.Label>
                    <FieldLabel.HelperTextContainer>
                      <FieldLabel.Message match={({ hasError }) => hasError}>
                        {formErrors.email
                          ? (formErrors.email.message as string)
                          : null}
                      </FieldLabel.Message>
                    </FieldLabel.HelperTextContainer>
                  </FieldLabel>
                  <CredentialForm
                    allRequired
                    showTfaInput={
                      showTfa ||
                      setTFARecoveryEmail.error?.codes.some(
                        code => code.type === 'tfa_incorrect'
                      )
                    }
                  />
                </Form>
              </FormProvider>
            </BaseModal.ContentWrapper>
            <BaseModal.Footer
              errorMessage={setTFARecoveryEmail.error?.error}
              errorType="error"
            >
              <Button
                form="set_recovery_email"
                disabled={!isValid}
                loading={isSubmitting}
              >
                Set Email
              </Button>
              <BaseModal.Close asChild>
                <Button level="secondary">Cancel</Button>
              </BaseModal.Close>
            </BaseModal.Footer>
          </BaseModal.Content>
        </BaseModal.Overlay>
      </BaseModal.Portal>
    </BaseModal>
  );
}
