//kessel
import { useCallback, useState } from 'react';

import {
  BaseModal,
  BaseModalProps,
  Button,
  ModalSize,
  Time
} from '@parsec/components';
import { WarpSubscription } from '@parsec/kessel';

//components
import { WarpLogo } from 'components';
import { useResumeTrialModalState } from 'lib/hooks';

import PurchaseError from '../PurchaseError';
import PurchaseSuccess from '../PurchaseSuccess';
import { Description, Section, HeadBack } from '../shared-components';

function Trial(props: {
  onSubmit(): void;
  interval: 'annual' | 'month';
  trialEnd?: string | null;
}) {
  const { onSubmit, interval, trialEnd } = props;

  const trialEndDate = trialEnd ? new Date(trialEnd) : undefined;

  return (
    <>
      <BaseModal.ContentWrapper css={{ padding: 0, overflowY: 'auto' }}>
        <Section data-testid="verify">
          <p>
            By hitting resume, your trial will auto&ndash;renew. We will charge
            you once your trial period ends{' '}
            {trialEndDate && (
              <>
                (
                <strong>
                  <Time date={trialEndDate} />
                </strong>
                )
              </>
            )}{' '}
            and we will keep charging you <strong>{interval}ly</strong> until
            you cancel the subscription. You can cancel at any time.
          </p>

          <p>
            If you would like to update your billing information or card on file
            you may do so from the dashboard.
          </p>
        </Section>
      </BaseModal.ContentWrapper>
      <BaseModal.Footer>
        <Button onClick={onSubmit}>Resume Trial</Button>
        <BaseModal.Close asChild>
          <Button level="secondary">Cancel</Button>
        </BaseModal.Close>
      </BaseModal.Footer>
    </>
  );
}

interface ResumeTrialModalProps extends BaseModalProps {
  subscription?: WarpSubscription;
  onCloseAutoFocus?: (e: Event) => void;
}

export default function ResumeTrialModal(props: ResumeTrialModalProps) {
  const {
    open: openProp,
    onOpenChange,
    subscription,
    onCloseAutoFocus
  } = props;
  const [open, setOpen] = useState(openProp);
  const isModalOpen = Boolean(open || openProp);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [onOpenChange]
  );

  const { error, step, setStep, setError, handleResume } =
    useResumeTrialModalState();

  return (
    <BaseModal open={isModalOpen} onOpenChange={handleOpenChange}>
      <BaseModal.Portal>
        <BaseModal.Overlay>
          <BaseModal.Content
            size={ModalSize.Medium}
            onCloseAutoFocus={onCloseAutoFocus}
          >
            <BaseModal.BlockHeader
              type="brand"
              css={{ display: 'grid', gridRowGap: '1.2rem' }}
            >
              <BaseModal.Title>
                {error
                  ? 'Uh oh!'
                  : step === 'success'
                    ? undefined
                    : `Resume your trial`}
              </BaseModal.Title>
              <WarpLogo />
              <BaseModal.Description asChild>
                <Description>
                  Enhance the ways you game, work and whatever else.
                </Description>
              </BaseModal.Description>
            </BaseModal.BlockHeader>

            {error ? (
              <BaseModal.ContentWrapper css={{ padding: 0, overflowY: 'auto' }}>
                <PurchaseError
                  error={error}
                  newModal
                  onStartOver={() => {
                    setError('');
                    setStep('trial');
                  }}
                />
              </BaseModal.ContentWrapper>
            ) : step === 'trial' ? (
              <Trial
                onSubmit={async () => {
                  await handleResume();
                  setStep('success');
                }}
                interval={
                  subscription?.plan_id === 'warp-yearly' ? 'annual' : 'month'
                }
                trialEnd={subscription?.trial_end}
              />
            ) : (
              <>
                <BaseModal.ContentWrapper
                  css={{ padding: 0, overflowY: 'auto' }}
                >
                  <PurchaseSuccess
                    headline="Feel that? That's the feeling of an even better Parsec."
                    description={
                      <>
                        Thanks for resuming your Warp trial. Your sub lets us
                        continue making Parsec awesome.
                        <br />
                        Now enough mushy stuff — go enjoy all the perks.
                      </>
                    }
                  />
                </BaseModal.ContentWrapper>
                <BaseModal.Footer>
                  <BaseModal.Close asChild>
                    <HeadBack>Head Back to Parsec</HeadBack>
                  </BaseModal.Close>
                </BaseModal.Footer>
              </>
            )}
          </BaseModal.Content>
        </BaseModal.Overlay>
      </BaseModal.Portal>
    </BaseModal>
  );
}
