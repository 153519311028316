import { useRef } from 'react';

// @parsec
import { AppRule, appRule, schema } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useMeData } from './me';
import { useGetTeamRolePermissionSummary } from './permission';
import { useKessel } from './Provider';
import { useAllTeamMachinesData } from './teamMachine';
import { useTeamMemberData } from './teamMember';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('app_rule');

export function useAppRuleData() {
  return useQueryData(key.all(), schema.paginatedAppRuleData);
}

/******************************************************************************
 * Get all Team App Rules
 ******************************************************************************/

export function useGetAllAppRules() {
  const kessel = useKessel();
  const meCache = useMeData();
  const me = meCache.get();
  const perms = useGetTeamRolePermissionSummary().data?.team;

  const canManage =
    (perms?.manage_app_rules ||
      perms?.manage_app_rules_app_config ||
      perms?.manage_app_rules_security_config ||
      perms?.manage_default_app_rules_app_config ||
      perms?.manage_default_app_rules_security_config ||
      perms?.assign_team_members_to_app_rules) ??
    false;
  const canAssign = perms?.assign_team_members_to_app_rules ?? false;

  const vars = {
    team_id: me?.team_id ?? '',
    offset: 0,
    limit: 200
  };
  const varsRef = useRef(vars);
  const keepPreviousData = varsRef.current.team_id === vars.team_id;
  varsRef.current = vars;

  const enabled = Boolean(me?.team_id) && (canManage || canAssign);

  const queryErrorMessage = "Couldn't get rulesets.";

  const result = useQuery(
    key.list(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.appRule.getAppRules(req);
      if (!!res.body?.data) {
        // Sort: default first; then alphanumeric
        res.body?.data.sort((a, b) => {
          if (a.is_default) return -1;
          if (b.is_default) return 1;
          return a.name.localeCompare(b.name);
        });
      }
      return res.body;
    },
    {
      enabled,
      keepPreviousData,
      meta: {
        errorMessage: queryErrorMessage
      }
    }
  );

  const error = useWrapError(result.error, {
    error: queryErrorMessage
  });

  return { ...result, error };
}

/******************************************************************************
 * Get Team App Rule
 ******************************************************************************/

export function useGetAppRule(
  app_rule_id: string,
  onSuccess?: (data: AppRule) => void
) {
  const kessel = useKessel();
  const meCache = useMeData();
  const me = meCache.get();
  const perms = useGetTeamRolePermissionSummary();
  const canManage = perms.data?.team.manage_app_rules ?? false;

  const vars = {
    team_id: me?.team_id ?? '',
    app_rule_id
  };

  const enabled = Boolean(me?.team_id || canManage);

  const result = useQuery(
    key.detail(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.appRule.getAppRule(req);
      return res.body?.data;
    },
    {
      enabled,
      onSuccess
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get rulesets."
  });
  return { ...result, error };
}

/******************************************************************************
 * Create Team App Rule
 ******************************************************************************/

export function useCreateAppRule() {
  const kessel = useKessel();
  const appRuleCache = useAppRuleData();

  const meCache = useMeData();
  const me = meCache.get();
  const teamId = me?.team_id ?? '';

  async function mutationFn(vars: Omit<appRule.CreateAppRuleReq, 'team_id'>) {
    const res = await kessel.appRule.createAppRule({
      ...vars,
      team_id: teamId
    });
    return res.body?.data;
  }

  const result = useMutation(mutationFn, {
    onSuccess: () => appRuleCache.invalidate()
  });

  const error = useWrapError(result.error, {
    error: "Couldn't create ruleset."
  });
  return { ...result, error };
}

/******************************************************************************
 * Create Team App Rule V2
 ******************************************************************************/
export function useCreateAppRuleV2() {
  const kessel = useKessel();
  const appRuleCache = useAppRuleData();
  const meCache = useMeData();
  const me = meCache.get();
  const teamId = me?.team_id ?? '';

  async function mutationFn(vars: Omit<appRule.CreateAppRuleV2Req, 'team_id'>) {
    const res = await kessel.appRule.createAppRuleV2({
      ...vars,
      team_id: teamId
    });
    return res.body?.data;
  }

  const result = useMutation(mutationFn, {
    onSuccess: () => appRuleCache.invalidate()
  });

  const error = useWrapError(result.error, {
    error: "Couldn't create ruleset."
  });
  return { ...result, error };
}

/******************************************************************************
 * Update Team App Rule
 ******************************************************************************/

export function useUpdateAppRule() {
  const kessel = useKessel();
  const appRulesCache = useAppRuleData();
  const appRuleCache = useAppRuleData();

  const meCache = useMeData();
  const me = meCache.get();
  const teamId = me?.team_id ?? '';

  async function mutationFn(vars: Omit<appRule.UpdateAppRuleReq, 'team_id'>) {
    const res = await kessel.appRule.updateAppRule({
      ...vars,
      team_id: teamId
    });
    return res.body?.data;
  }

  const result = useMutation(mutationFn, {
    onSuccess: () => {
      appRulesCache.invalidate();
      appRuleCache.invalidate();
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't update ruleset."
  });
  return { ...result, error };
}

/******************************************************************************
 * Update Team App Rule V2
 ******************************************************************************/
export function useUpdateAppRuleV2() {
  const kessel = useKessel();
  const appRulesCache = useAppRuleData();
  const appRuleCache = useAppRuleData();
  const meCache = useMeData();
  const me = meCache.get();
  const teamId = me?.team_id ?? '';

  async function mutationFn(vars: Omit<appRule.UpdateAppRuleV2Req, 'team_id'>) {
    const res = await kessel.appRule.updateAppRuleV2({
      ...vars,
      team_id: teamId
    });
    return res.body?.data;
  }

  const result = useMutation(mutationFn, {
    onSuccess: () => {
      appRulesCache.invalidate();
      appRuleCache.invalidate();
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't update ruleset."
  });
  return { ...result, error };
}

/******************************************************************************
 * Delete Team App Rule
 ******************************************************************************/

export function useDeleteAppRule() {
  const kessel = useKessel();
  const appRulesCache = useAppRuleData();
  const appRuleCache = useAppRuleData();
  const teamMembersCache = useTeamMemberData();
  const teamMachinesCache = useAllTeamMachinesData();

  const meCache = useMeData();
  const me = meCache.get();
  const teamId = me?.team_id ?? '';

  async function mutationFn(vars: Omit<appRule.DeleteAppRuleReq, 'team_id'>) {
    return await kessel.appRule.deleteAppRule({
      ...vars,
      team_id: teamId
    });
  }

  const result = useMutation(mutationFn, {
    onSuccess: () => {
      appRulesCache.invalidate();
      teamMembersCache.invalidate();
      teamMachinesCache.invalidate();
      appRuleCache.invalidate();
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't delete ruleset."
  });
  return { ...result, error };
}

/******************************************************************************
 * Assign Team App Rule to Team Members or Team Machines
 ******************************************************************************/

export function useAssignAppRule() {
  const kessel = useKessel();
  const appRuleCache = useAppRuleData();
  const teamMembersCache = useTeamMemberData();
  const teamMachinesCache = useAllTeamMachinesData();

  const meCache = useMeData();
  const me = meCache.get();
  const teamId = me?.team_id ?? '';

  async function mutationFn(vars: Omit<appRule.AssignAppRuleReq, 'team_id'>) {
    if (vars.team_machine_ids?.length === 0 && vars.user_ids?.length === 0)
      return;
    await kessel.appRule.assignAppRule({
      ...vars,
      team_id: teamId
    });
  }

  const result = useMutation(mutationFn, {
    onSuccess: () => {
      appRuleCache.invalidate();
      teamMembersCache.invalidate();
      teamMachinesCache.invalidate();
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't assign ruleset."
  });
  return { ...result, error };
}

/******************************************************************************
 * Send an email for enforcing TFA
 ******************************************************************************/

export function useSendAppRuleEnforceTfaEmail() {
  const kessel = useKessel();
  const meCache = useMeData();
  const me = meCache.get();
  const teamId = me?.team_id ?? '';

  async function mutationFn(
    vars: Omit<appRule.SendAppRuleEnforceTfaEmailReq, 'team_id'>
  ) {
    await kessel.appRule.sendAppRuleEnforceTfaEmail({
      ...vars,
      team_id: teamId
    });
  }

  const result = useMutation(mutationFn);

  const error = useWrapError(result.error, {
    error: "Couldn't send 2FA enforcement email."
  });
  return { ...result, error };
}

/******************************************************************************
 * Send an email for enforcing SAML
 ******************************************************************************/

export function useSendAppRuleEnforceSamlEmail() {
  const kessel = useKessel();
  const meCache = useMeData();
  const me = meCache.get();
  const teamId = me?.team_id ?? '';

  async function mutationFn(
    vars: Omit<appRule.SendAppRuleEnforceSamlEmailReq, 'team_id'>
  ) {
    await kessel.appRule.sendAppRuleEnforceSamlEmail({
      ...vars,
      team_id: teamId
    });
  }

  const result = useMutation(mutationFn);

  const error = useWrapError(result.error, {
    error: "Couldn't send SAML enforcement email."
  });
  return { ...result, error };
}
