import { useEffect, useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { UserAgent, parse } from 'next-useragent';

import { Button, styled } from '@parsec/components';

import { MARKETING_URL } from 'lib/config';

import parsecWarp from 'lib/images/confirmation/parsec-warp@2x.png';

// STYLES
const Wrapper = styled('div', {
  display: 'grid',
  rowGap: '$xxxxlarge',
  justifyItems: 'center',
  width: '100%',
  paddingTop: '$large'
});

const Banner = styled('div', {
  width: '63.1rem',
  padding: '$xxlarge',
  marginTop: '5.8rem',
  marginBottom: '1rem',
  backgroundColor: '$samehada',
  display: 'grid',
  gridTemplateColumns: '11rem auto 15.1rem',
  alignItems: 'center',
  justifyItems: 'end',
  columnGap: '2.2rem',
  borderRadius: '$large'
});

const BannerLink = styled(Link, {
  fontSize: '1.4rem',
  fontWeight: '$bold',
  lineHeight: '$attribution',
  color: '$ultraDark',
  backgroundColor: '$consoleWhite',
  padding: '$small $xlarge',
  borderRadius: '1.8rem'
});

const BannerText = styled('p', {
  fontSize: '$attribution',
  fontWeight: '$bold'
});

const HowToLink = styled('a', {
  fontWeight: '$bold'
});

interface MeConfirmationProps {
  onCtaDownloadClick?(): void;
  onCtaWarpClick?(): void;
  onTeamsClick?(): void;
  onTutorialClick?(): void;
}

const MeConfirmation = ({
  onCtaDownloadClick,
  onCtaWarpClick,
  onTeamsClick,
  onTutorialClick
}: MeConfirmationProps) => {
  const [userAgent, setUserAgent] = useState<UserAgent>();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const ua = parse(window.navigator.userAgent);
      setUserAgent(ua);
    }
  }, []);

  return (
    <Wrapper>
      <Button size="large" href={`${MARKETING_URL}/downloads`}>
        <span onClick={onCtaDownloadClick}>
          {userAgent?.isAndroid
            ? 'Download for Android'
            : userAgent?.isMac
              ? 'Download for Mac'
              : userAgent?.os === 'Linux'
                ? 'Download for Linux'
                : userAgent?.isWindows
                  ? 'Download for Windows 64 Bit'
                  : 'Download Parsec'}
        </span>
      </Button>

      <HowToLink
        href="https://support.parsec.app/hc/en-us/categories/32361192574228-Parsec-App"
        target="_blank"
        rel="noreferrer"
      >
        <span onClick={onTutorialClick}>How do I use Parsec?</span>
      </HowToLink>

      <Banner>
        <Image
          src={parsecWarp.src}
          alt="Parsec Warp"
          width={110}
          height={42.6}
          priority={false}
        />
        <BannerText id="link-description">Already have the app?</BannerText>
        <BannerLink
          href="/me/warp?journey=confirmation_warp"
          aria-describedby="link-description"
          onClick={onCtaWarpClick}
        >
          Try Warp for Free
        </BannerLink>
      </Banner>
      <Link href="/setup" onClick={onTeamsClick}>
        Looking for Parsec for Teams?
      </Link>
    </Wrapper>
  );
};

export default MeConfirmation;
