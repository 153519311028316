'use client';

import { useCallback, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  styled,
  FieldLabel,
  Input,
  AlertModal,
  AlertModalProps,
  Button
} from '@parsec/components';
import { SUPPORT_LINKS } from '@parsec/constants';
import { useDeleteAccount } from '@parsec/queries';

const Form = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '$medium'
});

const H2 = styled('h2', {
  fontFamily: '$default',
  fontSize: '$attribution',
  lineHeight: '$attribution'
});

const P = styled('p', {
  marginBottom: '$xxxlarge'
});

const formSchema = z
  .object({
    currentEmail: z.string(),
    email: z.string().email({ message: 'Invalid email address' })
  })
  .refine(({ currentEmail, email }) => currentEmail === email, {
    message: `Email doesn't match current email.`,
    path: ['email']
  });

type FormValues = z.infer<typeof formSchema>;

interface DeleteAccountModalProps extends AlertModalProps {
  email: string;
}

export default function DeleteAccountModal(props: DeleteAccountModalProps) {
  const { open: openProp, onOpenChange, email: emailProp, children } = props;
  const [open, setOpen] = useState(false);

  const isAlertOpen = Boolean(open || openProp);

  const deleteAccount = useDeleteAccount();

  const formMethods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      currentEmail: emailProp
    }
  });

  const {
    handleSubmit,
    register,
    formState: { errors: formErrors, isValid, isSubmitting }
  } = formMethods;

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        formMethods.reset();
        deleteAccount.reset();
      }
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [deleteAccount, formMethods, onOpenChange]
  );

  const onFormSubmit: SubmitHandler<FormValues> = async (_values, e) => {
    e?.preventDefault();

    try {
      await deleteAccount.mutateAsync();
      handleOpenChange(false);
    } catch (_error) {
      console.error(_error);
      // noop
    }
  };

  return (
    <AlertModal open={isAlertOpen} onOpenChange={handleOpenChange}>
      {children ? (
        <AlertModal.Trigger asChild>{children}</AlertModal.Trigger>
      ) : null}
      <AlertModal.Portal>
        <AlertModal.Overlay>
          <AlertModal.Content size="large">
            <AlertModal.Header>
              <AlertModal.Title>Delete Account</AlertModal.Title>
            </AlertModal.Header>
            <AlertModal.ContentWrapper>
              <Form id="delete_account" onSubmit={handleSubmit(onFormSubmit)}>
                <P>
                  We&apos;re sorry to see you go (╥&#xfe4f;╥) if you have time,
                  please{' '}
                  <a
                    href={SUPPORT_LINKS.LEAVING_PARSEC_SURVEY}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    let us know why you&apos;re leaving Parsec
                  </a>{' '}
                  so we can make it better.
                </P>

                <H2>If you delete your account…</H2>
                <AlertModal.Description asChild unstyled>
                  <P>
                    Your account will immediately be permanently deleted. You
                    will no longer be able to log in or use Parsec. All of your
                    personal information will be deleted from Parsec&apos;s
                    servers.
                  </P>
                </AlertModal.Description>

                <H2>Enter your email to confirm:</H2>
                <FieldLabel hasError={Boolean(formErrors.email)}>
                  <FieldLabel.Label label="Email">
                    <Input
                      placeholder={emailProp}
                      hasError={Boolean(formErrors.email)}
                      {...register('email')}
                    />
                  </FieldLabel.Label>
                  <FieldLabel.HelperTextContainer>
                    <FieldLabel.Message match={({ hasError }) => hasError}>
                      {formErrors.email?.message}
                    </FieldLabel.Message>
                  </FieldLabel.HelperTextContainer>
                </FieldLabel>
              </Form>
            </AlertModal.ContentWrapper>
            <AlertModal.Footer
              errorMessage={deleteAccount.error?.error}
              errorType="error"
            >
              <Button
                form="delete_account"
                kind="error"
                disabled={!isValid || isSubmitting}
              >
                Delete Account
              </Button>

              <AlertModal.Cancel asChild>
                <Button level="secondary" disabled={isSubmitting}>
                  Nevermind, Go Back
                </Button>
              </AlertModal.Cancel>
            </AlertModal.Footer>
          </AlertModal.Content>
        </AlertModal.Overlay>
      </AlertModal.Portal>
    </AlertModal>
  );
}
