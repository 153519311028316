import { useCallback, useEffect, useState } from 'react';

import {
  styled,
  BillingCardPreview as BaseBillingCardPreview,
  Button,
  BaseModalProps,
  BaseModal,
  ModalSize
} from '@parsec/components';
import { Card } from '@parsec/kessel';
import {
  useCreateWarpBillingCard,
  useGetWarpBillingCard
} from '@parsec/queries';
import { parseError } from '@parsec/request';

import { useAlert, WarpLogo } from 'components';
import { BILLING_INFO_FORM_IDS } from 'lib/constants/formIds';

import {
  BillingInfoForm,
  NewCard as BaseNewCard,
  NewCardData
} from './Billing';

/** STYLES */
const ContentWrapper = styled(BaseModal.ContentWrapper, {
  position: 'relative',
  padding: 0,
  overflowY: 'auto'
});

const BillingInfoSection = styled('div', {
  padding: '$xlarge'
});

const ExistingCardSection = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 10rem',
  gridTemplateRows: '2rem auto',
  paddingBottom: '$xxxlarge'
});

const BillingCardPreview = styled(BaseBillingCardPreview, {
  gridColumnStart: 1,
  gridColumnEnd: 3
});

const StyledP = styled('p', {
  fontWeight: '$bold',
  fontSize: '$body'
});

const EditButton = styled('button', {
  background: 'transparent',
  fontSize: '$attribution',
  lineHeight: '$attribution',
  fontWeight: 'bold',
  color: '$primary500',
  padding: '0',
  justifySelf: 'right',
  alignSelf: 'center',
  cursor: 'pointer'
});

const CancelButton = styled(EditButton, {
  padding: '0',
  position: 'absolute',
  right: '$xlarge'
});

/** FUNCTIONAL COMPONENTS */

function ExistingCard(props: { card: Card; onEditClick?(): void }) {
  const { card, onEditClick } = props;
  return (
    <ExistingCardSection>
      <StyledP>Payment Method</StyledP>
      <EditButton onClick={onEditClick}>Edit</EditButton>
      <BillingCardPreview card={card} />
    </ExistingCardSection>
  );
}

function NewCard(props: { onCancelClick(): void }) {
  const { onCancelClick } = props;
  return (
    <div>
      <CancelButton onClick={onCancelClick}>Cancel</CancelButton>
      <BaseNewCard />
    </div>
  );
}

/** MAIN COMPONENT */
interface Props extends BaseModalProps {
  onCloseAutoFocus?: (e: Event) => void;
}

export default function EditCreditCardModal(props: Props) {
  const { open: openProp, onOpenChange, onCloseAutoFocus } = props;
  const [alert, setAlert] = useAlert({ inModal: true });
  const [shouldSaveCard, setShouldSaveCard] = useState(false);
  const createWarpBillingCard = useCreateWarpBillingCard();
  const warpBilling = useGetWarpBillingCard();
  const [open, setOpen] = useState(openProp);

  const isModalOpen = Boolean(open || openProp);

  const card = useGetWarpBillingCard();
  const hasCard = Boolean(card.data);
  const [isEditing, setIsEditing] = useState(true);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [onOpenChange]
  );

  useEffect(() => {
    setIsEditing(!hasCard);
  }, [hasCard]);

  useEffect(() => {
    setShouldSaveCard(isEditing);
  }, [isEditing]);

  const onSubmit = async (data: NewCardData) => {
    // No updates needed
    if (!data.token) {
      return;
    }

    try {
      await createWarpBillingCard.mutateAsync({
        token: data.token
      });
      setAlert({
        kind: 'success',
        title: 'Success',
        message: 'Your payment method has been updated.'
      });
      setIsEditing(false);
    } catch (err) {
      const error = parseError(err);
      setAlert({
        kind: 'error',
        title: 'Error',
        message: error.error ?? 'We were unable to update your billing card'
      });
    }
  };

  return (
    <BaseModal open={isModalOpen} onOpenChange={handleOpenChange}>
      <BaseModal.Portal>
        <BaseModal.Overlay>
          <BaseModal.Content
            size={ModalSize.Large}
            onCloseAutoFocus={onCloseAutoFocus}
          >
            {alert}
            <BaseModal.BlockHeader
              type="brand"
              css={{ display: 'grid', gridRowGap: '1.2rem' }}
            >
              <BaseModal.Title css={{ textTransform: 'uppercase' }}>
                Update Payment Method
              </BaseModal.Title>
              <WarpLogo />
              <BaseModal.Description>
                Enhance the ways you game, work and whatever else.
              </BaseModal.Description>
            </BaseModal.BlockHeader>
            <ContentWrapper>
              <BillingInfoSection>
                <BillingInfoForm<NewCardData>
                  id={BILLING_INFO_FORM_IDS.DEFAULT_ID}
                  onSubmit={onSubmit}
                >
                  {card.data && !isEditing ? (
                    <ExistingCard
                      card={card.data}
                      onEditClick={() => {
                        setShouldSaveCard(true);
                        setIsEditing(true);
                      }}
                    />
                  ) : (
                    <NewCard onCancelClick={() => setIsEditing(false)} />
                  )}
                </BillingInfoForm>
              </BillingInfoSection>
            </ContentWrapper>
            <BaseModal.Footer>
              <Button
                type="submit"
                id="submit_update_card"
                form={BILLING_INFO_FORM_IDS.DEFAULT_ID}
                level="primary"
                disabled={!shouldSaveCard}
                loading={
                  createWarpBillingCard.isLoading || warpBilling.isLoading
                }
              >
                Save
              </Button>
              <BaseModal.Close asChild>
                <Button level="secondary">Cancel</Button>
              </BaseModal.Close>
            </BaseModal.Footer>
          </BaseModal.Content>
        </BaseModal.Overlay>
      </BaseModal.Portal>
    </BaseModal>
  );
}
