// libraries
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

// @parsec
import {
  pushToDl,
  PasswordToggledJourneyGroup,
  PasswordToggledSteps
} from '@parsec/analytics';
import {
  styled,
  CredentialForm,
  ErrorMessage,
  FieldLabel,
  PasswordInputToggled,
  PasswordInput,
  createPasswordInputSchema,
  Modal
} from '@parsec/components';
import { useUpdatePassword } from '@parsec/queries';

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$large'
});

interface MutationError {
  error: string;
}

interface FormValues {
  password: string;
  new_password: string;
  repeat_password: string;
  tfa?: string;
  api_error?: string;
}

interface ChangePasswordModalProps {
  email: string;
  name: string;
  showTfa?: boolean;
  isOpen: boolean;
  onClose(): void;
  onAfterClose(): void;
}

export default function ChangePasswordModal(props: ChangePasswordModalProps) {
  const { name, email, showTfa, isOpen, onClose, onAfterClose } = props;

  const updatePassword = useUpdatePassword();

  const formSchema = z
    .object({
      password: z.string().min(1),
      repeat_password: z.string(),
      tfa: z.number().optional()
    })
    .merge(createPasswordInputSchema('new_password'))
    .refine(
      ({ new_password }) =>
        new_password.toLocaleLowerCase() !== email.toLocaleLowerCase() &&
        new_password.toLocaleLowerCase() !== name.toLocaleLowerCase(),
      {
        message: 'Your password cannot be your email address or username.',
        path: ['new_password']
      }
    )
    .refine(
      ({ new_password, repeat_password }) => new_password === repeat_password,
      {
        message: "Passwords don't match",
        path: ['repeat_password']
      }
    );

  const formMethods = useForm<FormValues>({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: zodResolver(formSchema)
  });

  const {
    register,
    setError,
    handleSubmit,
    formState: { isSubmitting, errors, isValid }
  } = formMethods;

  const onSubmit = handleSubmit(async values => {
    try {
      await updatePassword.mutateAsync({
        password: values.password,
        new_password: values.new_password,
        tfa: values.tfa?.toString()
      });

      onClose();
    } catch (error) {
      const typedError = error as MutationError;

      setError('api_error', {
        type: 'api',
        message: typedError.error
      });
    }
  });

  const onChangePasswordToggleShow = (isShowing: boolean) =>
    pushToDl<'partial'>({
      event: 'userEvent',
      event_name: 'journey',
      properties: {
        journey_group: PasswordToggledJourneyGroup.ChangePassword,
        journey_step_name: isShowing
          ? PasswordToggledSteps.Show
          : PasswordToggledSteps.Hide
      }
    });

  const onNewPasswordToggleShow = (isShowing: boolean) =>
    pushToDl<'partial'>({
      event: 'userEvent',
      event_name: 'journey',
      properties: {
        journey_group: PasswordToggledJourneyGroup.NewPassword,
        journey_step_name: isShowing
          ? PasswordToggledSteps.Show
          : PasswordToggledSteps.Hide
      }
    });

  const onNewPasswordRepeatToggleShow = (isShowing: boolean) =>
    pushToDl<'partial'>({
      event: 'userEvent',
      event_name: 'journey',
      properties: {
        journey_group: PasswordToggledJourneyGroup.NewPasswordRepeat,
        journey_step_name: isShowing
          ? PasswordToggledSteps.Show
          : PasswordToggledSteps.Hide
      }
    });

  return (
    <FormProvider {...formMethods}>
      <Modal
        title="Change Password"
        isOpen={isOpen}
        onClose={onClose}
        onAfterClose={onAfterClose}
        actions={[
          {
            text: 'Change Password',
            form: 'change_password',
            disabled: !isValid || isSubmitting
          },
          {
            text: 'Cancel',
            level: 'secondary',
            onClick: onClose,
            disabled: isSubmitting
          }
        ]}
      >
        <Form id="change_password" onSubmit={onSubmit}>
          <CredentialForm
            onToggleShow={onChangePasswordToggleShow}
            showTfaInput={
              showTfa ||
              updatePassword.error?.codes.some(
                code => code.type === 'tfa_incorrect'
              )
            }
          />
          <FieldLabel hasError={Boolean(errors['new_password'])}>
            <FieldLabel.Label label="New Password">
              <PasswordInput
                identifier="new_password"
                autoComplete="new-password"
                onToggleShow={onNewPasswordToggleShow}
              />
            </FieldLabel.Label>
          </FieldLabel>

          <FieldLabel hasError={Boolean(errors['repeat_password'])}>
            <FieldLabel.Label label="Re-type New Password">
              <PasswordInputToggled
                autoComplete="new-password"
                onToggleShow={onNewPasswordRepeatToggleShow}
                {...register('repeat_password')}
              />
            </FieldLabel.Label>

            <FieldLabel.HelperTextContainer>
              <FieldLabel.Message role="alert">
                {errors['api_error'] ? (
                  <ErrorMessage>{errors['api_error']?.message}</ErrorMessage>
                ) : null}

                {errors['repeat_password'] ? (
                  <ErrorMessage>
                    {errors['repeat_password']?.message}
                  </ErrorMessage>
                ) : null}
              </FieldLabel.Message>
            </FieldLabel.HelperTextContainer>
          </FieldLabel>
        </Form>
      </Modal>
    </FormProvider>
  );
}
