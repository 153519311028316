// libraries
import * as n from 'narrows';

// @parsec
import { ApiOptions } from './api';
import request from './request';
import { data, tfa, tfaBackupCode } from './schema';

export const getTFA = (options: ApiOptions) => () =>
  request({
    method: 'GET',
    url: `${options.kessel}/tfa`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware,
    validator: data(tfa)
  });

export const createTFASetupURI = (options: ApiOptions) => () =>
  request({
    method: 'POST',
    url: `${options.kessel}/tfa`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware,
    validator: data(n.string)
  });

export interface EnableTFAReq {
  password: string;
  code: string;
  email?: string;
}

export const enableTFA = (options: ApiOptions) => (body: EnableTFAReq) =>
  request({
    method: 'PUT',
    url: `${options.kessel}/tfa`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware,
    body,
    validator: data(n.array(n.record({ code: n.string, used: n.boolean })))
  });

export interface DisableTFAReq {
  password: string;
  tfa?: string;
}

export const disableTFA = (options: ApiOptions) => (body: DisableTFAReq) =>
  request({
    method: 'DELETE',
    url: `${options.kessel}/tfa`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware,
    body
  });

export interface SetTFARecoveryEmailReq {
  email: string;
  password: string;
  tfa: string;
}

export const setTFARecoveryEmail =
  (options: ApiOptions) => (body: SetTFARecoveryEmailReq) =>
    request({
      method: 'POST',
      url: `${options.kessel}/tfa/recovery-email`,
      headers: { Authorization: `Bearer ${options.token()}` },
      body
    });

export interface SendTfaResetEmailReq {
  email: string;
  password: string;
  captcha_token: string;
}

export const sendTfaResetEmail =
  (options: ApiOptions) => (body: SendTfaResetEmailReq) =>
    request({
      method: 'POST',
      url: `${options.kessel}/public/tfa/send-tfa-reset-email`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });

export interface ResetTfaReq {
  email: string;
  password: string;
  code: string;
  captcha_token: string;
}
// Need the One Time Passcode(OTP) code to reset TFA
export const resetTfa = (options: ApiOptions) => (body: ResetTfaReq) =>
  request({
    method: 'POST',
    url: `${options.kessel}/public/tfa/reset`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware,
    body
  });

export interface ResetTFABackupCodesReq {
  password: string;
  tfa?: string;
}

export const resetTFABackupCodes =
  (options: ApiOptions) => (body: ResetTFABackupCodesReq) =>
    request({
      method: 'POST',
      url: `${options.kessel}/tfa/codes`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(n.array(tfaBackupCode)),
      body
    });
