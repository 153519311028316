import { ReactNode, useCallback, useState } from 'react';

import {
  styled,
  Button,
  BaseModal,
  BaseModalProps,
  ModalSize,
  DefaultLoader
} from '@parsec/components';
import {
  useGetWarpBillingDetails,
  useUpdateWarpBillingDetails
} from '@parsec/queries';

import { WarpLogo, useAlert } from 'components';
import { BILLING_INFO_FORM_IDS } from 'lib/constants/formIds';

import { BillingInfoForm, Customer, CustomerData } from './Billing';

// wrapper for early return modal
const Modal = ({
  open,
  onOpenChange,
  children,
  footer,
  onCloseAutoFocus
}: BaseModalProps & {
  footer?: ReactNode;
  onCloseAutoFocus?: (e: Event) => void;
}) => {
  return (
    <BaseModal open={open} onOpenChange={onOpenChange}>
      <BaseModal.Overlay>
        <BaseModal.Content
          size={ModalSize.Large}
          onCloseAutoFocus={onCloseAutoFocus}
        >
          <BaseModal.BlockHeader
            type="brand"
            css={{
              display: 'grid',
              gridRowGap: '1.2rem'
            }}
          >
            <BaseModal.Title css={{ textTransform: 'uppercase' }}>
              Update Billing Details
            </BaseModal.Title>
            <WarpLogo />
            <BaseModal.Description asChild>
              <p>Update your Warp billing details.</p>
            </BaseModal.Description>
          </BaseModal.BlockHeader>
          <BaseModal.ContentWrapper css={{ position: 'relative' }}>
            {children}
          </BaseModal.ContentWrapper>
          {footer}
        </BaseModal.Content>
      </BaseModal.Overlay>
    </BaseModal>
  );
};

interface EditBillingDetailsModalProps extends BaseModalProps {
  onCloseAutoFocus?: (e: Event) => void;
}

export default function NewEditBillingDetailsModal(
  props: EditBillingDetailsModalProps
) {
  const { open: openProps, onOpenChange, onCloseAutoFocus } = props;

  const [open, setOpen] = useState(openProps);

  const isModalOpen = Boolean(open || openProps);

  const warpBilling = useGetWarpBillingDetails();
  const updateWarpBilling = useUpdateWarpBillingDetails();
  const [alert, setAlert] = useAlert({ inModal: true });

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        updateWarpBilling.reset();
      }
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [onOpenChange, updateWarpBilling]
  );

  const onSubmit = async (data: CustomerData) => {
    try {
      await updateWarpBilling.mutateAsync({
        ...data,
        company: data.company_name,
        email: data.billing_email,
        billing_address: {
          line1: data.address_1,
          line2: data.address_2,
          city: data.city,
          state: data.state ?? '',
          zip: data.postal_code,
          country: data.country
        }
      });
      setAlert({
        kind: 'success',
        title: 'Success',
        message: 'Successfully updated billing details.'
      });
    } catch (_err) {
      // no-op error is captured in footer
    }
  };

  if (warpBilling.isLoading) {
    return (
      <Modal open={isModalOpen} onOpenChange={handleOpenChange}>
        <DefaultLoader />
      </Modal>
    );
  }

  return (
    <Modal
      open={isModalOpen}
      onOpenChange={handleOpenChange}
      onCloseAutoFocus={onCloseAutoFocus}
      footer={
        <BaseModal.Footer
          errorMessage={updateWarpBilling.error?.error}
          errorType="error"
        >
          <Button
            type="submit"
            id="submit_update_customer"
            form={BILLING_INFO_FORM_IDS.DEFAULT_ID}
            loading={updateWarpBilling.isLoading}
            level="primary"
          >
            Update Account Info
          </Button>
          <BaseModal.Close asChild>
            <Button level="secondary">Cancel</Button>
          </BaseModal.Close>
        </BaseModal.Footer>
      }
    >
      {alert}
      <BillingInfoForm<CustomerData>
        id={BILLING_INFO_FORM_IDS.DEFAULT_ID}
        onSubmit={onSubmit}
      >
        <Customer
          customer={{
            ...warpBilling.data,
            email: warpBilling.data?.email,
            billing_address: {
              line1: warpBilling.data?.billing_address.line1 ?? '',
              line2: warpBilling.data?.billing_address.line2,
              city: warpBilling.data?.billing_address.city ?? '',
              state: warpBilling.data?.billing_address.state ?? '',
              zip: warpBilling.data?.billing_address.zip,
              country: warpBilling.data?.billing_address.country ?? ''
            }
          }}
          disabled={updateWarpBilling.isLoading}
          validateName={(_, formValues) => {
            return (
              Boolean(formValues.company_name) ||
              (Boolean(formValues.first_name) &&
                Boolean(formValues.last_name)) ||
              'Require company name OR first AND last name.'
            );
          }}
          requireCompanyName={false}
        />
      </BillingInfoForm>
    </Modal>
  );
}

export const FormWrapper = styled('div', {
  backgroundColor: '$carkol',
  borderRadius: '$medium',
  display: 'grid',
  alignItems: 'center',
  minHeight: '10rem',
  variants: {
    padding: {
      true: {
        padding: '$xxlarge'
      }
    }
  }
});

export const Subtitle = styled('h4', {
  fontSize: '$header',
  lineHeight: '$header',
  textTransform: 'uppercase'
});

export const Actions = styled('div', {
  marginTop: '1rem',
  marginBottom: '1rem',
  height: '4rem',
  stack: {
    gap: '$xxlarge',
    hdistribute: 'start',
    valign: 'center'
  }
});

export const Section = styled('section', {
  stack: {
    direction: 'vertical',
    gap: '$xlarge'
  },
  '&:not(:first-of-type)': {
    marginTop: '4.8rem',
    paddingTop: '4.8rem',
    boxShadow: 'inset 0 0.1rem 0 $colors$pancham'
  },
  variants: {
    border: {
      true: {
        paddingTop: '4.8rem',
        boxShadow: 'inset 0 0.1rem 0 $colors$pancham'
      },
      false: {}
    },
    borderless: {
      true: {
        paddingTop: '0 !important',
        boxShadow: 'none !important'
      },
      false: {}
    }
  }
});
