import {
  BaseModal,
  Button,
  ErrorMessage,
  LegacyModal,
  styled
} from '@parsec/components';

interface PurchaseErrorProps {
  error: string;
  onStartOver(): void;
  newModal?: boolean;
}

export default function PurchaseError(props: PurchaseErrorProps) {
  const { error, onStartOver, newModal = false } = props;

  return (
    <>
      <Section>
        <ErrorMessage>{error}</ErrorMessage>
      </Section>
      {newModal ? (
        <BaseModal.Footer>
          <Button kind="neutral" onClick={onStartOver}>
            Start Over
          </Button>
        </BaseModal.Footer>
      ) : (
        <LegacyModal.Actions>
          <Button kind="neutral" onClick={onStartOver}>
            Start Over
          </Button>
        </LegacyModal.Actions>
      )}
    </>
  );
}

const Section = styled('div', {
  padding: '$xxlarge',
  background: '$perfectGray',
  display: 'grid'
});
