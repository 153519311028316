// libraries
import { useCallback, useState } from 'react';

// @parsec
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  styled,
  FieldLabel,
  Input,
  BaseModalProps,
  BaseModal,
  Button,
  ModalSize,
  ErrorMessage
} from '@parsec/components';
import { useUpdateMe } from '@parsec/queries';

const INPUT_MAX_LENGTH = 255;

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$large'
});

const Info = styled('span', {
  justifySelf: 'center',
  fontSize: '$info',
  lineHeight: '$info',
  color: '$rhyhorn',
  textAlign: 'right',
  gridArea: 'right'
});

interface EditAccountModalProps extends BaseModalProps {
  id: number;
  name: string;
}

const formSchema = z.object({
  name: z
    .string()
    .min(1, { message: 'Username is required' })
    .max(INPUT_MAX_LENGTH, {
      message: `Username must be at most ${INPUT_MAX_LENGTH} characters long.`
    })
});

type FormValues = z.infer<typeof formSchema>;

export default function AccountModal(props: EditAccountModalProps) {
  const { open: openProp, onOpenChange, id, name: prevName, children } = props;
  const [open, setOpen] = useState(false);

  const isModalOpen = Boolean(open || openProp);

  const formMethods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: prevName
    }
  });

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        // reset form on opening to get latest prevName
        formMethods.reset({ name: prevName });
      }
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [formMethods, onOpenChange, prevName]
  );

  const {
    handleSubmit,
    register,
    watch,
    setError,
    formState: { errors: formErrors, isValid, isSubmitting }
  } = formMethods;

  const updateMe = useUpdateMe();

  const nameLength = watch('name', '').length;

  const onSubmit: SubmitHandler<FormValues> = async (values, e) => {
    e?.preventDefault();
    try {
      if (values.name === prevName) {
        setError('name', {
          message: 'New username cannot be the same as your previous username'
        });
        return;
      }
      await updateMe.mutateAsync({ name: values.name });
      handleOpenChange(false);
    } catch (_error) {
      // noop handled by mutation
    }
  };

  return (
    <BaseModal open={isModalOpen} onOpenChange={handleOpenChange}>
      {children ? (
        <BaseModal.Trigger asChild>{children}</BaseModal.Trigger>
      ) : null}
      <BaseModal.Portal>
        <BaseModal.Overlay>
          <BaseModal.Content size={ModalSize.Medium}>
            <BaseModal.Header>
              <BaseModal.Title>Edit Account</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.ContentWrapper>
              <Form id="edit_account" onSubmit={handleSubmit(onSubmit)}>
                {formErrors.name ? (
                  <ErrorMessage>{formErrors.name.message}</ErrorMessage>
                ) : null}
                <FieldLabel
                  isValid={isValid}
                  hasError={Boolean(formErrors.name)}
                >
                  <FieldLabel.Label label="Username">
                    <Input {...register('name')} />
                  </FieldLabel.Label>
                  <FieldLabel.HelperTextContainer>
                    <Info>#{id}</Info>
                    <FieldLabel.Count
                      match={nameLength >= 0}
                      count={nameLength}
                      maxCount={INPUT_MAX_LENGTH}
                    />
                  </FieldLabel.HelperTextContainer>
                </FieldLabel>
              </Form>
            </BaseModal.ContentWrapper>
            <BaseModal.Footer
              errorMessage={updateMe.error?.error}
              errorType="error"
            >
              <Button form="edit_account" disabled={!isValid || isSubmitting}>
                Confirm &amp; Save
              </Button>
              <BaseModal.Close asChild>
                <Button level="secondary">Cancel</Button>
              </BaseModal.Close>
            </BaseModal.Footer>
          </BaseModal.Content>
        </BaseModal.Overlay>
      </BaseModal.Portal>
    </BaseModal>
  );
}
