'use client';

import { useCallback, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  styled,
  CredentialForm,
  ErrorMessage,
  FieldLabel,
  Input,
  PasswordInput,
  createPasswordInputSchema,
  BaseModal,
  ModalSize,
  Button,
  BaseModalProps
} from '@parsec/components';
import { useUpdatePassword } from '@parsec/queries';

const Form = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$large'
});

// Need to type this out since z.merge doesn't infer types without extra work
type FormValues = {
  email: string;
  name: string;
  password: string;
  repeat_password: string;
  new_password: string;
  tfa: number;
  showTfa: boolean;
};

const formSchema = z
  .object({
    email: z.string(), // passed in as default value
    name: z.string(), // passed in as default value
    password: z.string().min(1, { message: 'Password is required.' }),
    repeat_password: z.string(),
    tfa: z.number().or(z.undefined()),
    showTfa: z.boolean() // internal
  })
  .merge(createPasswordInputSchema('new_password'))
  .refine(
    ({ new_password, email, name }) =>
      new_password.toLocaleLowerCase() !== email.toLocaleLowerCase() &&
      new_password.toLocaleLowerCase() !== name.toLocaleLowerCase(),
    {
      message: 'Your password cannot be your email address or username.',
      path: ['new_password']
    }
  )
  .refine(
    ({ new_password, repeat_password }) => new_password === repeat_password,
    {
      message: "Passwords don't match",
      path: ['repeat_password']
    }
  )
  .refine(({ showTfa, tfa }) => (showTfa ? Boolean(tfa) : true), {
    // only require tfa if showTfa is true
    message: '2FA code required',
    path: ['tfa']
  });

interface ChangePasswordModalProps extends BaseModalProps {
  email: string;
  name: string;
  showTfa?: boolean;
  children?: ReactNode;
}

export default function ChangePasswordModal(props: ChangePasswordModalProps) {
  const {
    open: openProp,
    onOpenChange,
    name,
    email,
    showTfa = false,
    children
  } = props;
  const [open, setOpen] = useState(false);

  const isModalOpen = Boolean(open || openProp);

  const updatePassword = useUpdatePassword();

  const formMethods = useForm<FormValues>({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: zodResolver(formSchema),
    defaultValues: {
      name,
      email,
      showTfa
    }
  });

  const resetModal = useCallback(() => {
    formMethods.reset();
    updatePassword.reset();
  }, [formMethods, updatePassword]);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        resetModal();
      }
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [onOpenChange, resetModal]
  );

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors: formErrors }
  } = formMethods;

  const onSubmit: SubmitHandler<FormValues> = async (values, e) => {
    e?.preventDefault();
    try {
      await updatePassword.mutateAsync({
        password: values.password,
        new_password: values.new_password,
        tfa: values.tfa?.toString()
      });
      handleOpenChange(false);
    } catch (_error) {
      // noop, error captured on mutation result
    }
  };

  return (
    <BaseModal open={isModalOpen} onOpenChange={handleOpenChange}>
      {children ? (
        <BaseModal.Trigger asChild>{children}</BaseModal.Trigger>
      ) : null}
      <BaseModal.Portal>
        <BaseModal.Overlay>
          <BaseModal.Content size={ModalSize.Large}>
            <BaseModal.Header>
              <BaseModal.Title>Change Password</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.ContentWrapper>
              <FormProvider {...formMethods}>
                <Form id="change_password" onSubmit={handleSubmit(onSubmit)}>
                  <CredentialForm
                    showTfaInput={
                      showTfa ||
                      updatePassword.error?.codes.some(
                        code => code.type === 'tfa_incorrect'
                      )
                    }
                  />
                  <FieldLabel hasError={Boolean(formErrors['new_password'])}>
                    <FieldLabel.Label label="New Password">
                      <PasswordInput
                        identifier="new_password"
                        autoComplete="new-password"
                      />
                    </FieldLabel.Label>
                  </FieldLabel>

                  <FieldLabel hasError={Boolean(formErrors['repeat_password'])}>
                    <FieldLabel.Label label="Re-type New Password">
                      <Input
                        type="password"
                        autoComplete="new-password"
                        {...register('repeat_password')}
                      />
                    </FieldLabel.Label>
                    <FieldLabel.HelperTextContainer>
                      <FieldLabel.Message match={({ hasError }) => hasError}>
                        {formErrors['repeat_password'] ? (
                          <ErrorMessage>
                            {formErrors['repeat_password']?.message}
                          </ErrorMessage>
                        ) : null}
                      </FieldLabel.Message>
                    </FieldLabel.HelperTextContainer>
                  </FieldLabel>
                </Form>
              </FormProvider>
            </BaseModal.ContentWrapper>
            <BaseModal.Footer
              errorMessage={updatePassword.error?.error}
              errorType="error"
            >
              <Button form="change_password" disabled={isSubmitting}>
                Change Password
              </Button>
              <BaseModal.Close asChild>
                <Button level="secondary" disabled={isSubmitting}>
                  Cancel
                </Button>
              </BaseModal.Close>
            </BaseModal.Footer>
          </BaseModal.Content>
        </BaseModal.Overlay>
      </BaseModal.Portal>
    </BaseModal>
  );
}
