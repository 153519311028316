//Kessel
import { CreateWarpSubscriptionReq } from '@parsec/kessel/src/warpSubscription'; // why isn't this in the default kessel import

import { Icon, Radio } from '@parsec/components';
import { WarpInterval, WarpPlanID } from '@parsec/kessel';
import { styled, keyframes } from '@parsec/styles';

interface WarpPricingProps {
  currentPlanId?: WarpPlanID;
  selectedPlanId: WarpPlanID;
  subtotal?: number;
  updateReq(args: Partial<CreateWarpSubscriptionReq>): void;
  isTrial?: boolean; // not used?
}

export default function WarpPricing(props: WarpPricingProps) {
  const { currentPlanId, selectedPlanId, subtotal, updateReq } = props;

  const isOnYearly = currentPlanId === WarpPlanID.Yearly;
  const isOnMonthly = currentPlanId === WarpPlanID.Monthly;
  const wantsYearly = selectedPlanId === WarpPlanID.Yearly;
  const wantsMonthly = selectedPlanId === WarpPlanID.Monthly;
  const annualPrice = toUSD(9999);
  const monthlyPrice = toUSD(999);

  return (
    <Section>
      <ContentTitle>Pick a plan:</ContentTitle>

      <Plans>
        <Plan>
          <Radio
            name="selectedInterval"
            id="period_year"
            value="year"
            checked={wantsYearly}
            disabled={isOnYearly}
            onChange={() => {
              updateReq({ plan_id: WarpPlanID.Yearly });
            }}
          />
          <PlanPrice htmlFor="period_year" disabled={isOnYearly}>
            <span>
              <strong>Yearly</strong>{' '}
              {isOnYearly ? (
                <CurrentPlan>(Current Plan)</CurrentPlan>
              ) : (
                '(Best deal ever. Get 2 months free.)'
              )}
            </span>

            <PlanAmount selected={wantsYearly}>{annualPrice}</PlanAmount>
          </PlanPrice>
        </Plan>

        <Plan>
          <Radio
            name="selectedInterval"
            id="period_month"
            value="month"
            disabled={isOnMonthly}
            checked={wantsMonthly}
            onChange={() => {
              updateReq({ plan_id: WarpPlanID.Monthly });
            }}
          />

          <PlanPrice htmlFor="period_month" disabled={isOnMonthly}>
            <span>
              <strong>Monthly</strong>{' '}
              {isOnMonthly && <CurrentPlan>(Current Plan)</CurrentPlan>}
            </span>
            <PlanAmount selected={wantsMonthly}>{monthlyPrice}</PlanAmount>
          </PlanPrice>
        </Plan>
      </Plans>

      <Item>
        <TotalLabel>Subtotal before tax</TotalLabel>
        {subtotal === undefined || isNaN(subtotal) ? (
          <div style={{ position: 'relative' }}>
            <Loader name="loader" />
          </div>
        ) : (
          <span>
            <TotalPrice>{toUSD(subtotal)}</TotalPrice>/{' '}
            {selectedPlanId === WarpPlanID.Yearly
              ? WarpInterval.Yearly
              : WarpInterval.Monthly}
          </span>
        )}
      </Item>
    </Section>
  );
}

const Section = styled('div', {
  padding: '$xxlarge $xxlarge',
  background: '#2c2c2d',
  display: 'grid'
});

const ContentTitle = styled('h4', {
  marginBottom: '$xxlarge',
  fontSize: '$header',
  display: 'grid'
});

const Plans = styled('div', {
  gap: '$large',
  boxShadow: 'inset 0 -0.1rem 0 #f9fafc0c', // no token
  paddingBottom: '$large',
  marginBottom: '$large',
  display: 'grid'
});

const Plan = styled('div', {
  display: 'flex',
  alignItems: 'center'
});

const PlanPrice = styled('label', {
  flex: 1,
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gap: '1rem',
  marginLeft: '$medium',
  fontSize: '$body',
  variants: {
    disabled: {
      true: {
        color: '$rhyhorn'
      }
    }
  }
});

const PlanAmount = styled('span', {
  color: '#f9fafc72', //no token
  variants: {
    selected: {
      true: {
        color: '$consoleWhite'
      }
    }
  }
});

const CurrentPlan = styled('span', {
  color: '#f9fafc4c' //no token
});

const Item = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '$rhyhorn',
  padding: '$xxlarge 0'
});

const TotalLabel = styled('span', {
  color: '$consoleWhite',
  marginLeft: '$medium'
});

const TotalPrice = styled('span', {
  fontSize: '$h4',
  lineHeight: '$h4',
  fontWeight: '$bold',
  color: '$consoleWhite',

  variants: {
    struck: {
      true: {
        color: '$rhyhorn',
        textDecoration: 'line-through'
      }
    }
  }
});

function toUSD(cents: number) {
  return `$${(cents / 100).toFixed(2)}`;
}

const spin = keyframes({
  '0%': { transform: 'translate(-50%, -50%) rotate(0deg)' },
  '100%': { transform: 'translate(-50%, -50%) rotate(360deg)' }
});

const Loader = styled(Icon, {
  position: 'absolute',
  top: '0',
  right: '50%',
  animation: `${spin} linear 2s infinite`,
  fontSize: '$header'
});
