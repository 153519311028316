import { useCallback, useState } from 'react';

import {
  TfaConfirm,
  TfaBackupCodes,
  Button,
  BaseModal,
  BaseModalProps,
  ModalSize
} from '@parsec/components';
import { useCreateTFASetupURI, useEnableTFA } from '@parsec/queries';
import { parseError } from '@parsec/request';

const CONFLICT_STATUS_CODE = 409;

interface EnableTfaModalProps extends BaseModalProps {
  onCloseAutoFocus?: (e: Event) => void;
}

export default function NewEnableTfaModal(props: EnableTfaModalProps) {
  const {
    open: openProp,
    onOpenChange,
    children,
    onCloseAutoFocus,
    ...rest
  } = props;
  const [open, setOpen] = useState(openProp);

  const isModalOpen = Boolean(open || openProp); // openProp is used for controlled component

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [onOpenChange]
  );

  const closeModal = useCallback(() => {
    handleOpenChange(false);
  }, [handleOpenChange]);

  const [isValid, setIsValid] = useState(false);
  const [codes, setCodes] = useState<string[]>([]);

  const handleDone = useCallback(
    (backups: string[]) => {
      if (!backups.length) {
        return closeModal();
      }
      setCodes(backups);
    },
    [closeModal]
  );

  const onValidate = useCallback((valid: boolean) => {
    setIsValid(valid);
  }, []);

  return (
    <BaseModal open={isModalOpen} onOpenChange={handleOpenChange} {...rest}>
      {children ? (
        <BaseModal.Trigger asChild>{children}</BaseModal.Trigger>
      ) : null}
      <BaseModal.Portal>
        <BaseModal.Overlay>
          <BaseModal.Content
            size={ModalSize.Large}
            onCloseAutoFocus={onCloseAutoFocus}
          >
            <BaseModal.Header>
              <BaseModal.Title>
                Enable Two-Factor Authentication
              </BaseModal.Title>
            </BaseModal.Header>
            {codes.length ? (
              <>
                <BaseModal.ContentWrapper>
                  <TfaBackupCodes
                    id="tfa_backup_form"
                    codes={codes}
                    onDone={closeModal}
                  />
                </BaseModal.ContentWrapper>
                <BaseModal.Footer>
                  <Button form="tfa_backup_form">Download Codes</Button>
                  <BaseModal.Close asChild>
                    <Button level="secondary">Cancel</Button>
                  </BaseModal.Close>
                </BaseModal.Footer>
              </>
            ) : (
              <>
                <BaseModal.ContentWrapper>
                  <Confirm onDone={handleDone} onValidate={onValidate} />
                </BaseModal.ContentWrapper>
                <BaseModal.Footer>
                  <Button form="tfa_confirm_form" disabled={!isValid}>
                    Enable 2FA
                  </Button>
                  <BaseModal.Close asChild>
                    <Button level="secondary">Cancel</Button>
                  </BaseModal.Close>
                </BaseModal.Footer>
              </>
            )}
          </BaseModal.Content>
        </BaseModal.Overlay>
      </BaseModal.Portal>
    </BaseModal>
  );
}

function Confirm(props: {
  onDone(backups: string[]): void;
  onValidate(isValid: boolean): void;
}) {
  const { onDone, onValidate } = props;

  const createTfaSetupURI = useCreateTFASetupURI();

  const handleSetup = useCallback(async () => {
    try {
      const res = await createTfaSetupURI.mutateAsync();
      return res.data;
    } catch (e) {
      const error = parseError(e);
      if (error.status === CONFLICT_STATUS_CODE) {
        onDone([]);
      }
      throw e;
    }
  }, [createTfaSetupURI, onDone]);

  const enableTFA = useEnableTFA();

  const handleConfirm = useCallback(
    async (code: string, password: string, email?: string) => {
      const res = await enableTFA.mutateAsync({ code, password, email });
      onDone(
        res
          .filter(enableCode => !enableCode.used)
          .map(unusedCode => unusedCode.code)
      );
    },
    [enableTFA, onDone]
  );

  return (
    <TfaConfirm
      id="tfa_confirm_form"
      onSetup={handleSetup}
      onConfirm={handleConfirm}
      onValidate={onValidate}
    />
  );
}
