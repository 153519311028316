import { useEffect, useRef, useState } from 'react';

import { Icon, VariantProps, styled } from '@parsec/components';

const ALERT_TIMEOUT_MS = 5000;

interface AlertWithTimeoutProps {
  kind?: 'error' | 'success';
  title: string;
  message: React.ReactNode;
  onClear?(): void;
}

export default function AlertWithTimeout({
  kind = 'error',
  title,
  message,
  onClear
}: AlertWithTimeoutProps) {
  const onClearRef = useRef(onClear);
  onClearRef.current = onClear;

  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    const onClear = onClearRef.current;
    if (onClear) {
      timeout.current = setTimeout(onClear, ALERT_TIMEOUT_MS);
    }
  }, [title, message]);

  return <Alert kind={kind} title={title} message={message} />;
}

export interface AlertProps extends VariantProps<typeof Wrapper> {
  kind: 'error' | 'success';
  title: string;
  message: React.ReactNode;
}

function Alert({ kind, title, message, ...rest }: AlertProps) {
  return (
    <Wrapper className={`${kind}-alert`} kind={kind} {...rest}>
      <StyledIcon name={kind === 'error' ? 'ban' : 'check'} />
      <strong>{title}</strong>
      <span>{message}</span>
    </Wrapper>
  );
}

export function useAlert({ inModal = false }: { inModal?: boolean } = {}) {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const [alert, setAlert] = useState<{
    kind: 'error' | 'success';
    title: string;
    message: React.ReactNode;
  } | null>(null);

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => setAlert(null), ALERT_TIMEOUT_MS);
  }, [alert]);

  const alertElem = alert ? <Alert {...alert} inModal={inModal} /> : null;
  return [alertElem, setAlert] as [JSX.Element, typeof setAlert];
}

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateAreas: '"icon title" "icon copy"',
  alignItems: 'center',
  gridColumnGap: '1.8rem',
  position: 'fixed',
  top: '$xxxlarge',
  left: '50%',
  borderRadius: '0.6rem',
  padding: '$large 1.8rem',
  transform: 'translateX(-50%)',
  zIndex: '1000',
  maxWidth: 'calc(100vw - 20rem)',
  variants: {
    inModal: {
      true: {
        top: 'unset'
      }
    },
    kind: {
      error: {
        backgroundColor: '$error500'
      },
      success: {
        backgroundColor: '$success500'
      }
    }
  }
});

const StyledIcon = styled(Icon, {
  gridArea: 'icon',
  width: '2.4rem',
  height: '2.4rem'
});
