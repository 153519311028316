import {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useEffect,
  useState
} from 'react';

import { boolean } from 'narrows';

import { useStickyState } from '@parsec/hooks';
import { Downtime, Maintenance } from '@parsec/kessel';
import { captureException } from '@parsec/sentry';
import { styled } from '@parsec/styles';
import * as time from '@parsec/time';

import { noop, storageAvailable } from '../../utils';
import { IncidentStatus } from '../SharedType';

import { DEFAULT_POLL_TIME } from './constants';
import { usePollApiInterval } from './usePollApiInterval';

const Button = styled('button', {
  cursor: 'pointer',
  fontWeight: '$bold',
  fontSize: '$newBody',
  textAlign: 'right',
  whiteSpace: 'nowrap',
  lineHeight: '$info',
  marginLeft: 'auto',
  color: '$ultraDark',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$info',
        lineHeight: '$body'
      }
    }
  }
});

interface ContextProps {
  incidentStatus: IncidentStatus;
  incidentText: string | JSX.Element;
  incidentTitle?: string;
  isDismissible: boolean;
  onIncidentClose: () => void;
  showIncidentBanner: boolean;
  actionBtn?: ReactNode;
}

export const Context = createContext<ContextProps>({
  incidentStatus: IncidentStatus.Positive,
  incidentText: '',
  isDismissible: false,
  onIncidentClose: noop,
  showIncidentBanner: false
});

export const useIncidentNotification = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'useIncidentNotification must be used within an IncidentNotificationContext.Provider. Wrap a parent component in <IncidentNotificationProvider> to fix this error.'
    );
  }
  return context;
};

export interface IncidentNotificationContextProps {
  children: ReactNode;
  incidentCount: number;
  isDismissible: boolean;
  isDisplayed: boolean;
  status: IncidentStatus;
  text: string | JSX.Element;
  externalDowntimeURL: string;
  externalMaintenanceURL: string;
}

/**
 * Clears localStorage of previous incidents. Will delete all incidents PRIOR to `incidentCount` in localStorage.
 * @param currentCount number
 */
// UNCOMMENT WHEN YOU WANT TO CLEAR PREV INCIDENTS
function clearPrevIncidents(incidentCount: number) {
  if (storageAvailable('localStorage')) {
    for (let count = 0; count < incidentCount; count += 1) {
      window.localStorage.removeItem(`showIncidentBanner-${count}`);
    }
  } else {
    console.warn('No access to localStorage.');
  }
}

export const IncidentNotificationProvider = ({
  children,
  incidentCount,
  isDismissible,
  isDisplayed,
  status,
  text,
  externalDowntimeURL,
  externalMaintenanceURL
}: IncidentNotificationContextProps) => {
  const [isOpen, setIsOpen] = useStickyState(
    isDisplayed,
    `showIncidentBanner-${incidentCount}`,
    boolean
  );

  const [externalDowntime, setExternalDowntime] = useState<Downtime>();
  const [maintenanceStatus, setMaintenanceStatus] = useState<Maintenance>();

  const handleMaintenancePoll = (pollRes: Maintenance) => {
    const currentUnixTime = Math.floor(Date.now() / time.SECOND);
    if (pollRes.expiration_time && pollRes.expiration_time <= currentUnixTime) {
      // status available and maintenance is over, hide expired alert
      setMaintenanceStatus(undefined);
    } else {
      setMaintenanceStatus(pollRes);
    }
  };

  // Poll for maintenance status
  usePollApiInterval<Maintenance>({
    url: externalMaintenanceURL,
    delay: maintenanceStatus?.poll_period_m
      ? maintenanceStatus.poll_period_m * time.MINUTE
      : DEFAULT_POLL_TIME,
    onStatusAvailable: handleMaintenancePoll
  });

  useEffect(() => {
    async function getExternalDowntime(downtimeURL: string) {
      if (!downtimeURL) {
        return;
      }

      try {
        const res = await fetch(downtimeURL);

        if (res.status === 404) {
          // downtime will return a 404 status when there is no downtime.
          // so we'll do nothing.
        } else {
          const downtime: Downtime = await res.json();
          setExternalDowntime(downtime);
        }
      } catch (err) {
        // failed to fetch
        captureException(err, {
          tags: { component: 'IncidentNotificationProvider', downtimeURL }
        });
      }
    }

    getExternalDowntime(externalDowntimeURL);
  }, [setExternalDowntime, externalDowntimeURL]);

  const downtime = useMemo(() => {
    if (externalDowntime === undefined || !externalDowntime.display) {
      // downtime will take precedence over maintenance
      if (
        maintenanceStatus !== undefined &&
        maintenanceStatus.display === true
      ) {
        return {
          incidentStatus: IncidentStatus.Neutral,
          actionBtn: (
            <Button
              as="a"
              href={maintenanceStatus.link_url}
              target="_blank"
              rel="noreferrer"
              version="newFont"
            >
              {maintenanceStatus.link_title}
            </Button>
          ),
          incidentText: maintenanceStatus.message,
          isDismissible: false,
          showIncidentBanner: true,
          incidentTitle: maintenanceStatus.title
        };
      }
      // No external downtime and no external maintenance
      return {
        incidentStatus: status,
        incidentText: text,
        isDismissible,
        showIncidentBanner: isOpen
      };
    } else {
      return {
        incidentStatus:
          externalDowntime.type === 'error'
            ? IncidentStatus.Negative
            : IncidentStatus.Neutral,
        incidentText: (
          <>
            {externalDowntime.message}
            <br />
            Click{' '}
            <a
              href={externalDowntime.link_url}
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{' '}
            for more information.
          </>
        ),
        isDismissible: false,
        showIncidentBanner: true,
        incidentTitle: externalDowntime.title
      };
    }
  }, [
    externalDowntime,
    maintenanceStatus,
    status,
    text,
    isDismissible,
    isOpen
  ]);

  useEffect(() => {
    clearPrevIncidents(incidentCount);
  }, [incidentCount]);

  const value = useMemo(() => {
    function onIncidentClose() {
      if (downtime.isDismissible) {
        setIsOpen(!isOpen);
      }
    }

    return {
      incidentStatus: downtime.incidentStatus,
      incidentText: downtime.incidentText,
      incidentTitle: downtime.incidentTitle,
      isDismissible: downtime.isDismissible,
      onIncidentClose,
      showIncidentBanner: downtime.showIncidentBanner,
      actionBtn: downtime.actionBtn
    };
  }, [downtime, isOpen, setIsOpen]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
