// libraries
import { useCallback, useState } from 'react';

import { useDropzone, FileRejection } from 'react-dropzone';

// @parsec
import {
  styled,
  Avatar,
  Button,
  Icon,
  ErrorMessage,
  Input,
  BaseModal,
  ModalSize,
  BaseModalProps
} from '@parsec/components';
import { useUpdateAvatar } from '@parsec/queries';

// Maximum accepted file size for Avatar image in bytes
export const AVATAR_MAX_SIZE = 800000;

export const FILE_TOO_LARGE_ERROR = `File is too large. Maximum file size of ${
  AVATAR_MAX_SIZE * 0.001
} kb`;

const Form = styled('form', {
  display: 'grid',
  rowGap: '$medium',
  justifyItems: 'center'
});

const CurrentAvatar = styled(Avatar, {
  width: '16rem',
  height: '16rem'
});

const NewAvatar = styled('img', {
  borderRadius: '50%',
  width: '16rem',
  height: '16rem',
  objectFit: 'cover'
});

interface ChangeAvatarModalProps extends BaseModalProps {
  userId: number;
  nonce?: string;
}

export default function ChangeAvatarModal(props: ChangeAvatarModalProps) {
  const { open: openProp, onOpenChange, userId, nonce, children } = props;
  const [avatar, setAvatar] = useState<{ file: File; preview: string }>();
  const [error, setError] = useState<string | null>(null);

  const [modalOpen, setModalOpen] = useState(false);

  const isModalOpen = Boolean(modalOpen || openProp);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        setAvatar(undefined);
        setError(null);
      }
      setModalOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [onOpenChange]
  );

  const updateAvatar = useUpdateAvatar();

  const onDrop = (accepted: File[], rejected: FileRejection[]) => {
    if (rejected.length) {
      const fileSize = rejected[0].file.size;

      if (fileSize > AVATAR_MAX_SIZE) {
        setError(FILE_TOO_LARGE_ERROR);
      }
    }

    if (accepted.length) {
      setAvatar({
        file: accepted[0],
        preview: URL.createObjectURL(accepted[0])
      });
      setError(null);
    } else {
      setAvatar(undefined);
    }
  };

  const handleFormSubmit = useCallback(async () => {
    if (!avatar) {
      return;
    }
    try {
      setError(null);
      await updateAvatar.mutateAsync({ image: avatar.file });
      handleOpenChange(false);
    } catch (_err) {
      setError(
        updateAvatar.error?.error ??
          'An error occurred with updating your avatar'
      );
    }
  }, [avatar, handleOpenChange, updateAvatar]);

  const {
    getRootProps,
    getInputProps,
    open: openDropZone
  } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.jpeg', '.jpg', '.png'] },
    maxSize: AVATAR_MAX_SIZE
  });

  return (
    <BaseModal open={isModalOpen} onOpenChange={handleOpenChange}>
      {children ? (
        <BaseModal.Trigger asChild>{children}</BaseModal.Trigger>
      ) : null}
      <BaseModal.Portal>
        <BaseModal.Overlay>
          <BaseModal.Content size={ModalSize.Small}>
            <BaseModal.Header>
              <BaseModal.Title>Profile Picture</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.ContentWrapper>
              <Form id="update_avatar" onSubmit={handleFormSubmit}>
                <div {...getRootProps()}>
                  {avatar ? (
                    <NewAvatar src={avatar.preview} alt="New avatar" />
                  ) : (
                    <CurrentAvatar size={160} userId={userId} nonce={nonce} />
                  )}
                  <Input data-testid="drop-input" {...getInputProps()} />
                </div>

                <Button
                  icon={<Icon name="upload" />}
                  type="button"
                  level="link"
                  kind="primary"
                  onClick={openDropZone}
                >
                  Upload New
                </Button>

                {error ? <ErrorMessage>{error}</ErrorMessage> : null}
              </Form>
            </BaseModal.ContentWrapper>
            <BaseModal.Footer
              errorMessage={updateAvatar.error?.error}
              errorType="error"
            >
              <Button form="update_avatar" disabled={!avatar}>
                Confirm &amp; Save
              </Button>
              <BaseModal.Close asChild>
                <Button level="secondary">Cancel</Button>
              </BaseModal.Close>
            </BaseModal.Footer>
          </BaseModal.Content>
        </BaseModal.Overlay>
      </BaseModal.Portal>
    </BaseModal>
  );
}
